import { SelectProps } from '@mui/material';
import { REQUEST_STATE_CHIPS } from 'modules/requests/constant/stateRequest';
import StatusSelect from 'shared/components/StatusSelect';
import { ERequestStatus } from 'shared/types/requests';

interface IOfferStatusSelect {
  value: ERequestStatus;
  handleChange: SelectProps['onChange'];
  isSentEmail?: boolean;
}

const OfferStatusSelect = ({ handleChange, isSentEmail, value, ref: _ref, type: _type, ...rest }: IOfferStatusSelect & SelectProps) => {
  const filteredOptions = Object.fromEntries(
    Object.entries(REQUEST_STATE_CHIPS).filter(([key]) => {
      if (key === ERequestStatus.CreationFailed) {
        return value === ERequestStatus.CreationFailed;
      }
      if (key === ERequestStatus.SentPerEmail) {
        return isSentEmail === true;
      }
      return true;
    })
  );

  return (
    <StatusSelect
      {...rest}
      name="state"
      value={value}
      handleChange={handleChange}
      options={filteredOptions}
      sx={{ width: { xs: '100%', sm: 'auto' } }}
    />
  );
};

export default OfferStatusSelect;
