import { FormattedMessage, useIntl } from 'react-intl';
import { Autocomplete, Box, CardHeader, Divider, Grid, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import countries, { CountryType } from 'shared/data/countries';
import { IUserFormData } from 'shared/types/user';
import { getFlagUrl } from 'shared/utils/countries';

const CustomerAddressInfoSection = () => {
  const intl = useIntl();
  const { values, handleBlur, handleChange, setFieldValue } = useFormikContext<IUserFormData>();

  const chooseCountryHandler = (chosenCountry: CountryType | null) => {
    setFieldValue('country', chosenCountry === null ? '' : chosenCountry.code);
  };

  return (
    <>
      <CardHeader title={intl.formatMessage({ id: 'settings.address' })} sx={{ fontSize: '0.875rem', padding: '0 0 15px 0px' }} />
      <Divider style={{ margin: '0 0 10px 0' }} />
      <Box sx={{ padding: '10px 0px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={1.25}>
              <InputLabel htmlFor="user-street">
                <FormattedMessage id="users.street" />
              </InputLabel>
              <TextField
                fullWidth
                id="user-street"
                value={values.street}
                name="street"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: 'users.enter-here' })}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack spacing={1.25}>
              <InputLabel htmlFor="user-zip">
                <FormattedMessage id="users.zip-code" />
              </InputLabel>
              <TextField
                fullWidth
                id="user-postalCode"
                value={values.postalCode}
                name="postalCode"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: 'users.enter-here' })}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack spacing={1.25}>
              <InputLabel htmlFor="user-city">
                <FormattedMessage id="users.city" />
              </InputLabel>
              <TextField
                fullWidth
                id="user-city"
                value={values.city}
                name="city"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: 'users.enter-here' })}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1.25}>
              <InputLabel htmlFor="personal-country">
                <FormattedMessage id="users.country" />
              </InputLabel>
              <Autocomplete
                id="personal-country"
                fullWidth
                value={countries.filter((item) => item.code === values?.country)[0]}
                onBlur={handleBlur}
                onChange={(_event, newValue) => chooseCountryHandler(newValue)}
                options={countries}
                autoHighlight
                isOptionEqualToValue={(option, value) => option.code === value?.code}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.code && (
                      <img loading="lazy" width={21} height={14} src={getFlagUrl(option.code)} alt={option.code.toLowerCase()} />
                    )}
                    <Typography sx={{ ml: option.code ? 1.25 : 0 }}>
                      {option.label} ({option.code}) +{option.phone}
                    </Typography>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={intl.formatMessage({ id: 'users.choose-a-country' })}
                    name="country"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password' // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CustomerAddressInfoSection;
