import { Stack, TextField, useTheme } from '@mui/material';
import * as React from 'react';
import { ChangeEvent, FocusEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import useGeneralSettings from 'shared/hooks/useGeneralSettings';

interface NumericInputProps {
  disableAdornmentPaddingEnd?: boolean;
  disableAdornmentPaddingStart?: boolean;
  inputLabel?: string;
  isDecimal?: boolean;
  maxDecimalDigits?: number;
  isMetric?: boolean;
  isImperial?: boolean;
  value?: string | number;
  onHandleInputChange: (value: number) => void;
  onBlur?: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement | HTMLDivElement>) => void;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  isCurrency?: boolean;
  size?: 'small' | 'medium';
  name?: string;
  width?: string;
  disabled?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  showDecimalZeros?: boolean;
  allowNegative?: boolean;
  hasBackground?: boolean;
  hideAdornment?: boolean;
}

/**
 * Component for numeric input field
 * @param {string} name - name of the input field, used in formik, which is stored in database
 * @param {string} inputLabel - label of the input field
 * @param {boolean} isDecimal - flag to allow decimals
 * @param {number} maxDecimalDigits - maximum number of decimal digits
 * @param {boolean} isMetric - flag to use metric system
 * @param {boolean} isImperial - flag to use imperial system
 * @param {string | number} value - value of the input field
 * @param {function} onHandleInputChange - function to handle input change
 * @param {function} onBlur - function to handle blur event
 * @param {function} onKeyDown - function to handle key down event
 * @param {React.ReactNode} startAdornment - start adornment of the input field
 * @param {React.ReactNode} endAdornment - end adornment of the input field
 * @param {boolean} isCurrency - flag to use currency format
 * @param {'small' | 'medium'} size - size of the input field
 * @param {string} width - width of the input field
 * @param {boolean} disabled - flag to disable the input field
 * @param {'left' | 'center' | 'right'} textAlign - text alignment of the input field
 * @param {boolean} showDecimalZeros - flag to show decimal zeros
 * @param {boolean} allowNegative - flag to allow negative values
 * @param {boolean} hasBackground - flag to set background color
 * @param {boolean} hideAdornment - flag to hide adornments
 * @returns {JSX.Element} - NumericInput component
 */

const NumericInput: React.FC<NumericInputProps> = ({
  disableAdornmentPaddingEnd = false,
  disableAdornmentPaddingStart = false,
  isCurrency = false,
  isDecimal = false,
  maxDecimalDigits = 9,
  isMetric = false,
  isImperial = false,
  inputLabel,
  value = '',
  startAdornment,
  endAdornment,
  onHandleInputChange,
  onBlur,
  onKeyDown,
  size = 'medium',
  name,
  width,
  disabled,
  textAlign,
  showDecimalZeros = false,
  allowNegative = false,
  hasBackground = false,
  hideAdornment = false
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const { regionalSettings } = useGeneralSettings();
  const currencySign = regionalSettings?.currency?.symbol;
  const decimalSeparator = regionalSettings?.currency?.decimalSeparator;
  const groupSeparator = regionalSettings?.currency?.thousandSeparator;
  const isCH = regionalSettings?.currency?.code === 'CHF';

  let currentDecimalSeparator = decimalSeparator;
  let currentGroupSeparator = groupSeparator;

  if (!isCurrency) {
    if (!isDecimal) {
      currentGroupSeparator = ' ';
    }
    if (isCH) {
      currentDecimalSeparator = ',';
      currentGroupSeparator = ' ';
    } else if (isMetric) {
      currentDecimalSeparator = ',';
    } else if (isImperial) {
      currentDecimalSeparator = '.';
    }
  }

  const parseNumber = (value: string): number => {
    const normalizedValue = value.replace(new RegExp(`\\${currentGroupSeparator}`, 'g'), '').replace(/[.,]/g, '.');

    const parsedValue = parseFloat(normalizedValue);
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  const formatNumber = (value: number): string => {
    let [integerPart, decimalPart] = value.toFixed(maxDecimalDigits).split('.');

    const regex = /\B(?=(\d{3})+(?!\d))/g;
    if (currentGroupSeparator) {
      integerPart = integerPart.replace(regex, currentGroupSeparator);
    }

    if (showDecimalZeros) {
      decimalPart = decimalPart.padEnd(maxDecimalDigits, '0');
    } else {
      decimalPart = decimalPart.replace(/0+$/, '');
    }

    const formattedNumber = decimalPart ? `${integerPart}${currentDecimalSeparator}${decimalPart}` : integerPart;

    return formattedNumber;
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const [shouldSelectInput, setShouldSelectInput] = useState<boolean>(false);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) {
      onKeyDown(event);
    }

    const allowedKeys = ['Backspace', 'Tab', 'Escape', 'ArrowLeft', 'ArrowRight', 'Delete', 'Home', 'End'];

    if (allowedKeys.includes(event.key)) {
      return;
    }

    const { key, ctrlKey, metaKey } = event;
    const isNumber = /^[0-9]$/.test(key);
    const isDecimalSeparator = key === '.' || key === ',';
    const isNegativeSign = key === '-' && allowNegative;

    // Verhindern von Dezimaltrennzeichen, wenn isDecimal false ist
    if (!isDecimal && isDecimalSeparator) {
      event.preventDefault();
      return;
    }

    const currentDecimalCount = (inputValue.match(/[.,]/g) || []).length;
    if (isDecimalSeparator && currentDecimalCount >= 1) {
      event.preventDefault();
      return;
    }

    if (isNegativeSign) {
      event.preventDefault();
      setInputValue((prevValue) => {
        const newValue = prevValue.startsWith('-') ? prevValue.slice(1) : '-' + prevValue;
        return newValue;
      });
      setShouldSelectInput(true);
      return;
    }

    if (!isNumber && !isDecimalSeparator && !(ctrlKey || metaKey)) {
      event.preventDefault();
    }
  };

  const [inputValue, setInputValue] = useState<string>(formatNumber(parseNumber(value.toString())));

  useEffect(() => {
    setInputValue(formatNumber(parseNumber(value.toString())));
  }, [value]);

  useEffect(() => {
    if (shouldSelectInput && inputRef.current) {
      const inputElement = inputRef.current;
      const valueLength = inputValue.length;

      const selectionStart = inputValue.startsWith('-') ? 1 : 0;

      inputElement.setSelectionRange(selectionStart, valueLength);
      setShouldSelectInput(false);
    }
  }, [shouldSelectInput, inputValue]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;

    if (allowNegative) {
      const isNegative = newValue.includes('-');
      newValue = newValue.replace(/-/g, '');
      if (isNegative) {
        newValue = '-' + newValue;
      }
    }

    setInputValue(newValue);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onBlur) {
      onBlur(event);
    }

    const incorrectDecimalSeparator = currentDecimalSeparator === ',' ? '.' : ',';

    const adjustedInputValue = (inputValue ?? '').replace(
      new RegExp(`\\${incorrectDecimalSeparator}`, 'g'),
      currentDecimalSeparator ?? ','
    );

    const parsedValue = parseNumber(adjustedInputValue);

    setInputValue(formatNumber(parsedValue));
    onHandleInputChange(parsedValue);
  };

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  return (
    <Stack
      width={width ?? 'unset'}
      gap={0.5}
      sx={{
        backgroundColor: hasBackground ? theme.palette.background.paper : 'inherit',
        '& .MuiInputBase-adornedEnd': { paddingRight: disableAdornmentPaddingEnd ? '0 !important' : '' },
        '& .MuiInputBase-adornedStart': { paddingLeft: disableAdornmentPaddingStart ? '0 !important' : '' },
        '& .MuiOutlinedInput-input': { textAlign: textAlign ?? 'left' }
      }}
    >
      <TextField
        disabled={disabled}
        variant="outlined"
        label={inputLabel}
        name={name}
        size={size}
        placeholder={intl.formatMessage({ id: 'events.type-here' })}
        InputProps={{
          startAdornment: hideAdornment ? null : isCurrency ? currencySign : startAdornment,
          endAdornment: hideAdornment ? null : endAdornment
        }}
        onKeyDown={handleKeyDown}
        type="text"
        value={inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        inputRef={inputRef}
        onFocus={handleFocus}
        inputProps={{
          inputMode: isDecimal ? 'decimal' : 'numeric',
          pattern: isDecimal ? `[0-9${currentDecimalSeparator}]*` : '[0-9]*'
        }}
      />
    </Stack>
  );
};

export default NumericInput;
