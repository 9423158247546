import PreviewAnswerWrapper from 'modules/organisation-plan-preview/components/Shared/PreviewAnswerWrapper';
import PreviewWidgetWrapper from 'modules/organisation-plan-preview/components/Shared/PreviewWidgetWrapper';
import { DisplayMode } from 'modules/organisation-plan-templates/types';
import { OrganisationPlanTimePickerWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanTimePickerWithReplacementType';
import { Layout } from 'react-grid-layout';
import { TimeFormatEnum } from 'shared/configs/config';
import { useCurrentDateFormat, useCurrentTimeFormat } from 'shared/hooks/regionalSettings';
import { applyFormatToDateTimePickerValue } from '../../../../utils/applyFormatToDateTimePickerValue';

interface IPreviewTimePickerWidgetProps {
  element: OrganisationPlanTimePickerWithReplacementType;
  layoutItem: Layout;
}

const PreviewTimePickerWidget = ({ element, layoutItem }: IPreviewTimePickerWidgetProps) => {
  const {
    replacementLabel,
    label,
    date,
    isMainLabelHidden,
    widgetId,
    sectionId,
    typeValue,
    isHighlighted,
    isBottomDivider,
    listingOption,
    displayMode
  } = element;
  const isColumnMode = (displayMode ?? DisplayMode.row) === DisplayMode.column;
  const dateFormat = useCurrentDateFormat();
  const timeFormat = useCurrentTimeFormat();
  const isTwelveHoursFormat = timeFormat === TimeFormatEnum.Twelve;
  const dateAccordingToFormat = applyFormatToDateTimePickerValue({
    type: typeValue,
    date,
    dateFormat: dateFormat.format,
    isTwelveHoursFormat
  });
  const labelAfterCheck = replacementLabel || label || '';
  return (
    <PreviewWidgetWrapper
      isBottomDivider={isBottomDivider}
      isHighlighted={isHighlighted}
      widgetId={widgetId}
      sectionId={sectionId}
      layoutItem={layoutItem}
      questionLabel={labelAfterCheck}
      isQuestionLabelHidden={isMainLabelHidden || !isColumnMode}
    >
      <PreviewAnswerWrapper
        index={0}
        displayMode={displayMode ?? DisplayMode.row}
        listingOption={listingOption}
        questionLabel={labelAfterCheck}
        isQuestionLabelHidden={isMainLabelHidden}
        numericValueLabel={dateAccordingToFormat}
        isLabelHidden={isMainLabelHidden || !element?.date}
      />
    </PreviewWidgetWrapper>
  );
};

export default PreviewTimePickerWidget;
