import { Checkmark20Filled, Edit20Regular } from '@fluentui/react-icons';
import { IconButton, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

interface IEditOnHoverProps {
  fontFamily?: string;
  variant:
    | 'body1'
    | 'body2'
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'overline'
    | 'subtitle1'
    | 'subtitle2';
  value: string;
  onChange: (value: string) => void;
}
const EditOnHover = ({ variant, value, fontFamily, onChange }: IEditOnHoverProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const [internalValue, setInternalValue] = useState('');

  const theme = useTheme();

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const handleEdit = () => {
    if (isEdit) {
      if (internalValue.trim() !== '' || internalValue !== null) {
        onChange(internalValue);
      } else {
        setInternalValue(value);
      }
    }
    setIsEdit(!isEdit);
  };

  const handleTextClick = () => {
    setIsEdit(true);
  };

  const handleInternalChange = (value: string) => {
    setInternalValue(value.trim());
  };

  return (
    <Stack
      marginTop="20px"
      minHeight="40px"
      width="fit-content"
      direction="row"
      alignItems="center"
      spacing="5px"
      sx={{
        '&:hover': { backgroundColor: theme.palette.action.hover },
        '&:hover .edit-icon': { opacity: '1' },
        padding: isEdit ? '2px 5px 2px 2px' : '2px 5px 2px 5px',
        marginLeft: '-5px',
        borderRadius: '8px',
        cursor: isEdit ? 'auto' : 'pointer',
        backgroundColor: isEdit ? theme.palette.background.paper : 'transparent',
        boxShadow: isEdit ? 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px' : 'none'
      }}
      onClick={handleTextClick}
    >
      {!isEdit ? (
        <Typography fontFamily={fontFamily} variant={variant}>
          {value}
        </Typography>
      ) : (
        <TextField
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleEdit();
          }}
          sx={{ backgroundColor: theme.palette.background.paper, width: 'max-content' }}
          autoFocus
          value={internalValue}
          onBlur={handleEdit}
          onChange={(e) => handleInternalChange(e.target.value)}
          disabled={!isEdit}
          fullWidth
        />
      )}
      <IconButton color="primary" className="edit-icon" size="small" onClick={handleEdit} sx={{ opacity: isEdit ? 1 : '0' }}>
        {!isEdit ? <Edit20Regular /> : <Checkmark20Filled />}
      </IconButton>
    </Stack>
  );
};

export default EditOnHover;
