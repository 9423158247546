import { Box, Button, CircularProgress, Stack, Tooltip } from '@mui/material';
import { useGetInvoiceArticleItems } from 'api/hooks/invoices/useGetInvoiceArticleItems';
import { useGetInvoicePrepaymentItems } from 'api/hooks/invoices/useGetInvoicePrepaymentItems';
import { useFormikContext } from 'formik';
import { EEditorType, IEditorArticle } from 'modules/editor/types/editor';
import DialogImportWarning from 'modules/invoices/components/DialogImportWarning';
import {
  addBasePriceUnitWordingToArticle,
  addInnerIdToArticle,
  checkIsImportedFromEvent,
  generateListArticlesFromEventArticles,
  updateOrderArticle
} from 'modules/invoices/utils/prepareData';
import { PdfTemplateParameters } from 'modules/settings/types';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import EditorAndPreview from 'shared/components/DraftTextEditor/EditorAndPreview';
import EditOnHover from 'shared/components/EditOnHover';
import { EInvoiceEditorArticleType, IInvoiceEditor } from 'shared/types/invoice';

interface IIsImportAgain {
  isOpen: boolean;
  items: IEditorArticle[];
  type: EInvoiceEditorArticleType | null;
}

interface IGreetingTextImportButtons {
  parameters: PdfTemplateParameters;
  fontFamily: string;
}

const initialIsImportAgain: IIsImportAgain = { isOpen: false, items: [], type: null };

const GreetingTextImportButtons = ({ parameters, fontFamily }: IGreetingTextImportButtons) => {
  const intl = useIntl();
  const [importedAgain, setImportedAgain] = useState<IIsImportAgain>(initialIsImportAgain);
  const { values, setFieldValue } = useFormikContext<IInvoiceEditor>();
  const { mutateAsync: getPrepayments, isLoading: isPrepaymentLoading } = useGetInvoicePrepaymentItems({});
  const { mutateAsync: getArticles, isLoading: isArticlesLoading } = useGetInvoiceArticleItems();

  const handleCloseWarningImport = () => {
    setImportedAgain(initialIsImportAgain);
  };

  const handleImportPrepayments = async () => {
    const type = EInvoiceEditorArticleType.ImportedPrepayment;
    const res = await getPrepayments(values?.data?.id); // EventId
    const articlesWithInnerId = addInnerIdToArticle(res);

    if (checkIsImportedFromEvent(values?.articles, type)) {
      return setImportedAgain({
        isOpen: true,
        items: articlesWithInnerId,
        type: EInvoiceEditorArticleType.ImportedPrepayment
      });
    }
    setFieldValue('articles', updateOrderArticle(values?.articles, articlesWithInnerId));
  };

  const handleImportArticles = async () => {
    const type = EInvoiceEditorArticleType.Imported;
    const res = await getArticles(values?.data?.id); // TODO: O: delete if ok : values.eventId
    const articlesWithInnerId = addInnerIdToArticle(res);
    const resArticles = addBasePriceUnitWordingToArticle(articlesWithInnerId, intl);

    if (checkIsImportedFromEvent(values?.articles, type)) {
      return setImportedAgain({
        isOpen: true,
        items: resArticles,
        type: EInvoiceEditorArticleType.Imported
      });
    }
    setFieldValue('articles', updateOrderArticle(values?.articles, resArticles));
  };

  const handleImportWithReplacement = () => {
    if (!importedAgain?.type) return;
    const newArticles = generateListArticlesFromEventArticles(importedAgain?.type, values?.articles, importedAgain?.items);
    setFieldValue('articles', updateOrderArticle(newArticles));
    handleCloseWarningImport();
  };

  const handleImportAll = () => {
    setFieldValue('articles', updateOrderArticle(values?.articles, importedAgain?.items));
    handleCloseWarningImport();
  };

  const handleSubjectChange = (value: string) => {
    setFieldValue('settingsTemplate.documentSubject', value);
  };

  return (
    <Box mt="80px">
      <Box m="0 40px">
        <EditOnHover
          fontFamily={fontFamily}
          variant={'h4'}
          value={
            !values.settingsTemplate?.documentSubject?.trim()
              ? intl.formatMessage({ id: 'invoice.document-subject' })
              : values.settingsTemplate?.documentSubject
          }
          onChange={(value) => handleSubjectChange(value)}
        />
        <Stack spacing={1.25} mb="10px" sx={{ fontFamily: fontFamily }}>
          <EditorAndPreview
            entityType={EEditorType.Invoices}
            type="greetingText"
            defaultFont={fontFamily}
            editorType="PdfTemplate"
            htmlText={values?.settingsTemplate?.welcomeText ?? ''}
            parameters={parameters}
            onChange={(newValue) => setFieldValue('settingsTemplate', { ...values.settingsTemplate, welcomeText: newValue })}
          />
        </Stack>
      </Box>
      <Stack direction="row" gap="10px" justifyContent="flex-end" alignItems="center" width="100%" mb="10px">
        <Tooltip arrow title={!values?.data?.id ? intl.formatMessage({ id: 'invoices.invoice-editor-tooltip-assigned-event' }) : ''}>
          <>
            <Button
              size="medium"
              variant="outlined"
              color="secondary"
              onClick={handleImportPrepayments}
              disabled={isPrepaymentLoading || !values?.data}
              startIcon={isPrepaymentLoading && <CircularProgress size={'20px'} />}
            >
              <FormattedMessage id="invoices.invoice-editor-import-prepayments" />
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="secondary"
              onClick={handleImportArticles}
              disabled={isArticlesLoading || !values?.data}
              startIcon={isArticlesLoading && <CircularProgress size={'20px'} />}
            >
              <FormattedMessage id="invoices.invoice-editor-import-articles" />
            </Button>
          </>
        </Tooltip>
      </Stack>
      <DialogImportWarning
        isOpen={importedAgain?.isOpen}
        closeDialog={handleCloseWarningImport}
        handleImportWithReplacement={handleImportWithReplacement}
        handleImportAll={handleImportAll}
      />
    </Box>
  );
};

export default GreetingTextImportButtons;
