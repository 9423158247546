import { ITenantFormData } from 'shared/types/tenants';

export interface ISelectParams {
  label: string;
  value: string;
  formatId?: string;
}

export interface GeneralSettingsTenantDataForForm extends ITenantFormData {
  logo?: string;
  logovideo?: string;
}

export enum EmailTemplatePageType {
  EmailTemplate = 'EmailTemplate'
}

export interface PdfTemplateParameters {
  [key: string]: string;
}
