import { Stack } from '@mui/material';
import TemplateListingOption from 'modules/organisation-plan-templates/components/widgets/Shared/TemplateListingOption';
import { ListingOption } from 'modules/organisation-plan-templates/types';
import { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import ReplacementTextButtons from 'shared/components/ReplacementText/Buttons';
import ReplacementTextInput from 'shared/components/ReplacementText/Input';
import usePrevious from 'shared/hooks/usePrevious';
import * as Styles from './Heading.styles';

interface IReplacementTextHeading {
  value?: string | null;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  content?: string;
  isLabelHiddenValue?: boolean;
  onLabelHiddenChange?: (value: boolean) => void;
  onReplacementLabelHiddenChange?: () => void;
  isHeadingReplacementOpen?: (value: boolean) => void;
  listingOption?: ListingOption;
  index?: number;
  isQuestion?: boolean;
}
const ReplacementTextHeading = memo(
  ({
    value,
    index,
    onChange,
    content,
    isLabelHiddenValue,
    onLabelHiddenChange,
    onReplacementLabelHiddenChange,
    isHeadingReplacementOpen,
    listingOption,
    isQuestion
  }: IReplacementTextHeading) => {
    const [isReplacementValueShown, setIsReplacementValueShown] = useState(false);
    const [isLabelHidden, setIsLabelHidden] = useState(false);

    useEffect(() => {
      if (!!value) {
        setIsReplacementValueShown(true);
        setIsLabelHidden(false);
      } else if (isLabelHiddenValue === true) {
        setIsLabelHidden(true);
        setIsReplacementValueShown(false);
      }
    }, [isLabelHiddenValue, value]);

    const prevValue = usePrevious(value);

    const replaceValueCallback = useCallback(() => {
      setIsReplacementValueShown((prev) => {
        const newValue = !prev;
        isHeadingReplacementOpen?.(newValue);
        if (prev && prevValue && onReplacementLabelHiddenChange) {
          onReplacementLabelHiddenChange();
        }
        return newValue;
      });
    }, [isHeadingReplacementOpen, onReplacementLabelHiddenChange, prevValue]);

    const labelShownCallback = useCallback(() => {
      setIsLabelHidden((prev) => {
        const newValue = !prev;
        onLabelHiddenChange?.(newValue);
        return newValue;
      });
    }, [onLabelHiddenChange]);

    const replacementText = <ReplacementTextInput isQuestion={isQuestion} onChange={onChange} value={value} />;

    const originalText = <Styles.OriginalTextWrapper isQuestion={isQuestion}>{content}</Styles.OriginalTextWrapper>;

    return (
      <Styles.LabelRow isHidden={isLabelHidden}>
        <Stack width="100%" direction="row" gap="10px" justifyContent="start" alignItems="center">
          <TemplateListingOption index={index} listingOption={listingOption} />
          {isReplacementValueShown ? replacementText : originalText}
        </Stack>
        <ReplacementTextButtons
          isReplacementValueShown={isReplacementValueShown}
          replaceValueCallback={replaceValueCallback}
          isLabelShown={isLabelHidden}
          labelShownCallback={labelShownCallback}
        />
      </Styles.LabelRow>
    );
  }
);

export default memo(ReplacementTextHeading);
