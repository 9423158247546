import { Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Breadcrumbs from 'shared/components/@extended/Breadcrumbs';
import Header from 'shared/components/Header';
import navigation from 'shared/components/menu-items';
import useConfig from 'shared/hooks/useConfig';
import Drawer from 'shared/layouts/MainLayout/Drawer';
import * as Styles from 'shared/layouts/MainLayout/MainLayout.styles';
import { RootStateProps } from 'shared/types/root';
import { selectIsDashboardCustomize } from 'store/dashboard/dasboard.selectors';
import { setCustomizeDashboard } from 'store/dashboard/dashboard.slice';
import { openDrawer } from 'store/menu';
import * as StylesDashboard from './DashboardLayout.styles';

const DashboardLayout = () => {
  const { container } = useConfig();
  const dispatch = useDispatch();
  const theme = useTheme();
  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;
  const handleDrawerToggle = () => {
    dispatch(openDrawer({ drawerOpen: !drawerOpen }));
  };
  const isCustomize = useSelector(selectIsDashboardCustomize);

  useEffect(() => {
    return () => {
      dispatch(setCustomizeDashboard(false));
    };
  }, [dispatch]);

  return (
    <Styles.MainLayoutBoxWrapper>
      <Header open={drawerOpen} handleDrawerToggle={handleDrawerToggle} withSidebar />
      <Drawer open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
      <StylesDashboard.MainWrapper theme={theme} isCustomize={isCustomize} component="main">
        <Toolbar variant="dense" />
        {container && (
          <Styles.MainLayoutContainerWrapper
            maxWidth={false}
            sx={{
              p: { xs: 0.5, sm: 1, md: 2, lg: 3, xl: 4 },
              maxHeight: 'calc(100dvh - 50px)',
              overflow: 'auto'
            }}
          >
            <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
            <Outlet />
          </Styles.MainLayoutContainerWrapper>
        )}
        {!container && (
          <Styles.NoContainerBoxWrapper>
            <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
            <Outlet />
            {/* <Footer /> */}
          </Styles.NoContainerBoxWrapper>
        )}
      </StylesDashboard.MainWrapper>
    </Styles.MainLayoutBoxWrapper>
  );
};

export default DashboardLayout;
