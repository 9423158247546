import OfferEditor from 'pages/Offer/OfferEditor';
import AuthGuard from 'shared/components/route-guard/AuthGuard';
import MainLayout from 'shared/layouts/MainLayout';
import { OFFERS_ROUTES } from './paths';

export const OffersRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout isHeaderVisible={false} isLeftMenuVisible={false} isHeaderPlaceholderVisible={true} />
        </AuthGuard>
      ),
      children: [
        {
          path: OFFERS_ROUTES.LINK_BY_OFFER_ID,
          element: (
            <AuthGuard role={['tenant_admin', 'customer', 'venue_manager']}>
              <OfferEditor />
            </AuthGuard>
          )
        }
      ]
    }
  ]
};
