import { Stack } from '@mui/material';
import { useGetOrganizationPlanTemplateById } from 'api/hooks/organisation-plan-templates/useCreateOrganisationPlanTemplate';
import { useGetOrganizationPlanTemplateBuilderById } from 'api/hooks/organisation-plan-templates/useCreateOrganizationPlanTemplateBuilder';
import { useGetQuestionnairesPagesById } from 'api/hooks/questionnaires/useGetQuestionnairesPagesById';
import { IGuestAndRoomsPageFormDataDB } from 'modules/builder/types';
import { findBuilderPageByType } from 'modules/builder/utils';
import OrganisationPlanTemplateHeader from 'modules/organisation-plan-templates/components/OrganisationPlanTemplateHeader';
import OrganisationPlanTemplateOptionsSection from 'modules/organisation-plan-templates/components/OrganisationPlanTemplateOptionsSection';
import OrganisationPlanTemplateSidebar from 'modules/organisation-plan-templates/components/OrganisationPlanTemplateSidebar';
import { GRID_LAYOUT_SIZE_ORGANISATION_PLAN } from 'modules/organisation-plan-templates/constants/organisationPlanTemplateBuilder';
import OrganisationPlanTemplateSectionsContainer from 'modules/organisation-plan-templates/containers/OrganisationPlanTemplateSectionsContainer';
import { getWidgetsAndFilteredSectionsOrgPlanTemplate } from 'modules/organisation-plan-templates/utils/getWidgetsAndFilteredSectionsOrgPlanTemplate';
import { preparedHeaderOptionsOrgPlanTemplate } from 'modules/organisation-plan-templates/utils/preparedHeaderOptionsOrgPlanTemplate';
import { checkHasDataChangesOrgPlan } from 'modules/organisation-plan-templates/utils/state';
import { QuestionnairePageType } from 'modules/questionnaires/types/questionnaries';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'shared/components/Loader';
import PageTitle from 'shared/components/PageTitle';
import PromptMessage from 'shared/components/PromptMessage';
import { EQuestionnairesSource } from 'shared/types/questionnaire-template';
import { useDispatch, useSelector } from 'store';
import { selectGetBaseState, selectHeaderOptions } from 'store/organisation-plan-template/organisation-plan-template.selectors';
import {
  setAllHeaderOptions,
  setOrganisationPlanStructure,
  setPrimaryState
} from 'store/organisation-plan-template/organisation-plan-template.slice';

const OrganisationPlanTemplatePageById = () => {
  const [isOpenModalWarning, setIsOpenModalWarning] = useState(false);

  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const { organisationPlanId, questionnaireTemplateId } = useParams();

  const headerOptions = useSelector(selectHeaderOptions);
  const primaryState = useSelector(selectGetBaseState);

  const { isLoading, data: allPages } = useGetQuestionnairesPagesById({
    id: questionnaireTemplateId ? Number(questionnaireTemplateId) : undefined,
    entitySource: EQuestionnairesSource.template
  });
  const { data: organizationPlanTemplateData, isLoading: isOrgPlanTemplateLoading } = useGetOrganizationPlanTemplateById(
    Number(organisationPlanId || 0),
    Number(questionnaireTemplateId || 0)
  );
  const { data: organizationPlanTemplateBuilderData, isLoading: isOrgPlanTemplateBuilderLoading } =
    useGetOrganizationPlanTemplateBuilderById(Number(organisationPlanId || 0), Number(questionnaireTemplateId || 0));

  const guestAndRoomsPage = findBuilderPageByType<IGuestAndRoomsPageFormDataDB>(QuestionnairePageType.GuestsAndRooms, allPages ?? []);

  useEffect(() => {
    const links = organizationPlanTemplateBuilderData?.links || [];
    if (!organizationPlanTemplateBuilderData) return;
    const headerOptions = preparedHeaderOptionsOrgPlanTemplate({
      guestAndRoomsPage,
      organizationPlanTemplateBuilderData,
      organizationPlanTemplateData,
      organizationPlanFormatMessage: intl.formatMessage({ id: 'events.organisation-plan' })
    });

    dispatch(setAllHeaderOptions(headerOptions));

    const { widgets, filteredSections } = getWidgetsAndFilteredSectionsOrgPlanTemplate({ organizationPlanTemplateBuilderData });

    dispatch(setOrganisationPlanStructure({ links, sections: filteredSections, usedWidgets: widgets }));
    dispatch(setPrimaryState({ links, sections: filteredSections, usedWidgets: widgets, headerOptions }));
  }, [organizationPlanTemplateBuilderData, organizationPlanTemplateData, guestAndRoomsPage, dispatch, intl]);

  const isChangeData = checkHasDataChangesOrgPlan(primaryState);

  if (isLoading || isOrgPlanTemplateLoading || isOrgPlanTemplateBuilderLoading) {
    return <Loader />;
  }

  const handleClose = () => {
    if (isChangeData && !isOpenModalWarning) {
      return setIsOpenModalWarning(true);
    }
    navigate(-1);
  };

  const handleHideWarning = () => {
    setIsOpenModalWarning(false);
  };

  return (
    <>
      <PageTitle title={headerOptions?.name} />
      <OrganisationPlanTemplateHeader handleClose={handleClose} />
      <Stack m="10px 0" direction="row" spacing={1} justifyContent="center">
        <Stack gap="10px" width={GRID_LAYOUT_SIZE_ORGANISATION_PLAN.widthGrid}>
          <OrganisationPlanTemplateOptionsSection />
          <OrganisationPlanTemplateSectionsContainer />
        </Stack>

        <OrganisationPlanTemplateSidebar />
      </Stack>

      <PromptMessage
        isDirtyForm={isChangeData}
        handleConfirm={handleClose}
        isOpenModalWarning={isOpenModalWarning}
        handleCloseModal={handleHideWarning}
      />
    </>
  );
};

export default OrganisationPlanTemplatePageById;
