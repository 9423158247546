import { ArticlePriceType, IPrice } from 'modules/articles/types';
import { IOrganisationPlanChoiceOptionLabels } from 'modules/organisation-plan-templates/types/organisationPlanChoiceWidgetType';
import { IQuestionnaireImageItemDataSingleWidget } from 'modules/questionnaires/types/questionnaries';

interface IGetNumericValuesWithUnitsProps {
  priceOption?: IPrice;
  selectedOption?: IQuestionnaireImageItemDataSingleWidget | IOrganisationPlanChoiceOptionLabels;
  isUnitLabelHidden?: boolean;
}

export const getNumericValuesWithUnits = ({ selectedOption, priceOption, isUnitLabelHidden }: IGetNumericValuesWithUnitsProps): string => {
  if (!priceOption || !selectedOption) return '';
  const isPricePerGuest = priceOption?.articlePriceType === ArticlePriceType.FlatRate && !priceOption?.flatRateInfo?.isTotalPrice;
  const isOnRequest = priceOption?.articlePriceType === ArticlePriceType.onRequest;
  const guestCount = selectedOption?.countOfGuests;
  const primaryValue = isPricePerGuest ? guestCount : selectedOption?.numericallyValue;
  const primaryUnit = (isOnRequest ? priceOption.onRequestInfo.onRequestPrice : priceOption?.unit) ?? '';
  const secondaryValue = selectedOption?.secondUnitValue;
  const secondaryUnit = isPricePerGuest ? priceOption?.flatRateInfo.secondUnit : priceOption?.numericallyInfo?.secondUnit;
  const numericFirstValue = isUnitLabelHidden || !primaryUnit ? (primaryValue ?? '') : `${primaryValue} (${primaryUnit})`;
  const numericSecondValue = isUnitLabelHidden || !secondaryUnit ? secondaryValue : `${secondaryValue} (${secondaryUnit})`;
  if (primaryValue === null) return '';
  return `${primaryValue && secondaryValue ? `${numericFirstValue}; ${numericSecondValue}` : (numericFirstValue ?? '')}`;
};
