import { FormattedMessage } from 'react-intl';
import { Question20Regular } from '@fluentui/react-icons';
import { NavItemType } from 'shared/types/menu';

const pages: NavItemType = {
  id: 'group-pages',
  title: <FormattedMessage id="pages" />,
  type: 'group',
  children: [
    {
      id: 'authentication',
      title: <FormattedMessage id="authentication" />,
      type: 'collapse',
      icon: Question20Regular,
      children: [
        {
          id: 'login',
          title: <FormattedMessage id="login" />,
          type: 'item',
          url: '/mantis/auth/login',
          target: true
        },
        {
          id: 'register',
          title: <FormattedMessage id="register" />,
          type: 'item',
          url: '/mantis/auth/register',
          target: true
        },
        {
          id: 'forgot-password',
          title: <FormattedMessage id="forgot-password" />,
          type: 'item',
          url: '/mantis/auth/forgot-password',
          target: true
        },
        {
          id: 'reset-password',
          title: <FormattedMessage id="reset-password" />,
          type: 'item',
          url: '/mantis/auth/reset-password',
          target: true
        },
        {
          id: 'check-mail',
          title: <FormattedMessage id="check-mail" />,
          type: 'item',
          url: '/mantis/auth/check-mail',
          target: true
        },
        {
          id: 'code-verification',
          title: <FormattedMessage id="code-verification" />,
          type: 'item',
          url: '/mantis/auth/code-verification',
          target: true
        }
      ]
    },
    {
      id: 'maintenance',
      title: <FormattedMessage id="maintenance" />,
      type: 'collapse',
      icon: Question20Regular,
      children: [
        {
          id: 'error-404',
          title: <FormattedMessage id="error-404" />,
          type: 'item',
          url: '/not-found/404',
          target: true
        },
        {
          id: 'error-500',
          title: <FormattedMessage id="error-500" />,
          type: 'item',
          url: '/mantis/maintenance/500',
          target: true
        },
        {
          id: 'coming-soon',
          title: <FormattedMessage id="coming-soon" />,
          type: 'item',
          url: '/mantis/maintenance/coming-soon',
          target: true
        },
        {
          id: 'under-construction',
          title: <FormattedMessage id="under-construction" />,
          type: 'item',
          url: '/mantis/maintenance/under-construction',
          target: true
        }
      ]
    },
    {
      id: 'contact-us',
      title: <FormattedMessage id="contact-us" />,
      type: 'item',
      url: '/mantis/contact-us',
      icon: Question20Regular,
      target: true
    },
    {
      id: 'pricing',
      title: <FormattedMessage id="pricing" />,
      type: 'item',
      url: '/mantis/pricing',
      icon: Question20Regular
    }
  ]
};

export default pages;
