import { Box, Button, CircularProgress, Stack } from '@mui/material';
import { useGetOfferArticleItemsSelected } from 'api/hooks/offers/useGetOfferArticleItemsSelected';
import { useFormikContext } from 'formik';
import { EEditorType, IEditorArticle } from 'modules/editor/types/editor';
import DialogImportWarning from 'modules/invoices/components/DialogImportWarning';
import { addBasePriceUnitWordingToArticle, addInnerIdToArticle, updateOrderArticle } from 'modules/invoices/utils/prepareData';
import { checkIsImportedArticle, generateListOfferArticlesFromDBArticles } from 'modules/offers/utils/prepareData';
import { PdfTemplateParameters } from 'modules/settings/types';
import { memo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import EditorAndPreview from 'shared/components/DraftTextEditor/EditorAndPreview';
import EditOnHover from 'shared/components/EditOnHover';
import TooltipWrapper from 'shared/components/TooltipWrapper';
import { EOfferEditorArticleType, IOfferEditor } from 'shared/types/offer';
import * as Styles from './GreetingTextImportButtons.styles';

interface IIsImportAgain {
  isOpen: boolean;
  items: IEditorArticle[];
  type: EOfferEditorArticleType | null;
}
interface IGreetingTextImportButtons {
  parameters: PdfTemplateParameters;
  fontFamily: string;
}
const initialIsImportAgain: IIsImportAgain = { isOpen: false, items: [], type: null };

const GreetingTextImportButtons = ({ parameters, fontFamily }: IGreetingTextImportButtons) => {
  const intl = useIntl();
  const [importedAgain, setImportedAgain] = useState<IIsImportAgain>(initialIsImportAgain);
  const { values, setFieldValue } = useFormikContext<IOfferEditor>();
  const { mutateAsync: getArticles, isLoading: isArticlesLoading } = useGetOfferArticleItemsSelected();

  const handleCloseWarningImport = () => {
    setImportedAgain(initialIsImportAgain);
  };

  const handleImportArticles = async () => {
    if (!values?.id) return;
    const type = EOfferEditorArticleType.Imported;
    const res = await getArticles(values?.id);

    const articlesWithInnerId = addInnerIdToArticle(res);
    const resArticles = addBasePriceUnitWordingToArticle(articlesWithInnerId, intl);

    if (checkIsImportedArticle(values?.articles as IEditorArticle<EOfferEditorArticleType>[] | [], type)) {
      return setImportedAgain({
        isOpen: true,
        items: resArticles,
        type: EOfferEditorArticleType.Imported
      });
    }
    setFieldValue('articles', updateOrderArticle(values?.articles, resArticles));
  };

  const handleImportWithReplacement = () => {
    if (!importedAgain?.type) return;
    const newArticles = generateListOfferArticlesFromDBArticles(
      importedAgain?.type,
      values?.articles as IEditorArticle<EOfferEditorArticleType>[],
      importedAgain?.items as IEditorArticle<EOfferEditorArticleType>[]
    );
    setFieldValue('articles', updateOrderArticle(newArticles));
    handleCloseWarningImport();
  };

  const handleImportAll = () => {
    setFieldValue('articles', updateOrderArticle(values?.articles, importedAgain?.items));
    handleCloseWarningImport();
  };

  const tooltipTextForDisabledButtons = () => {
    if (!!values?.data?.id) return null;
    return (
      <TooltipWrapper title={'invoices.invoice-editor-tooltip-assigned-event'}>
        <Styles.TooltipBox />
      </TooltipWrapper>
    );
  };

  const handleSubjectChange = (value: string) => {
    setFieldValue('settingsTemplate.documentSubject', value);
  };

  return (
    <Box mt="80px">
      <Box m="0 40px">
        <EditOnHover
          fontFamily={fontFamily}
          variant={'h4'}
          value={
            !values.settingsTemplate?.documentSubject?.trim()
              ? intl.formatMessage({ id: 'offer.document-subject' })
              : values.settingsTemplate?.documentSubject
          }
          onChange={(value) => handleSubjectChange(value)}
        />
        <Stack spacing={1} sx={{ fontFamily: fontFamily }}>
          <EditorAndPreview
            entityType={EEditorType.Offers}
            type="greetingText"
            defaultFont={fontFamily}
            editorType="PdfTemplate"
            htmlText={values?.settingsTemplate?.welcomeText ?? ''}
            parameters={parameters}
            onChange={(newValue) => setFieldValue('settingsTemplate', { ...values.settingsTemplate, welcomeText: newValue })}
          />
        </Stack>
      </Box>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" width="100%" mb="10px">
        <Styles.BlockTooltip>
          <Button
            fullWidth
            size="medium"
            variant="outlined"
            color="secondary"
            onClick={handleImportArticles}
            disabled={isArticlesLoading || !values?.data}
            startIcon={isArticlesLoading && <CircularProgress size={'20px'} />}
          >
            <FormattedMessage id="offer.editor-import-selected-articles" />
          </Button>
          {tooltipTextForDisabledButtons()}
        </Styles.BlockTooltip>
      </Stack>
      <DialogImportWarning
        isOpen={importedAgain?.isOpen}
        closeDialog={handleCloseWarningImport}
        handleImportWithReplacement={handleImportWithReplacement}
        handleImportAll={handleImportAll}
      />
    </Box>
  );
};

export default memo(GreetingTextImportButtons);
