import PreviewAnswerWrapper from 'modules/organisation-plan-preview/components/Shared/PreviewAnswerWrapper';
import PreviewWidgetWrapper from 'modules/organisation-plan-preview/components/Shared/PreviewWidgetWrapper';
import { getNumericValuesWithUnits } from 'modules/organisation-plan-preview/utils/getNumericValueWithUnit';
import { renderInputLabel } from 'modules/organisation-plan-preview/utils/renderInputLabelHandler';
import { DisplayMode } from 'modules/organisation-plan-templates/types';
import {
  MultipleImageChoiceWithReplacementWidgetType,
  SingleImageChoiceWithReplacementWidgetType
} from 'modules/organisation-plan-templates/types/organisationPlanImageWidgetTypes';
import { useMemo, useState } from 'react';
import { Layout } from 'react-grid-layout';
import { getStorageFileUrl } from 'shared/utils/getStorageFileUrl';

interface IPreviewImageChoiceWidgetProps {
  element: SingleImageChoiceWithReplacementWidgetType | MultipleImageChoiceWithReplacementWidgetType;
  layoutItem: Layout;
}

const PreviewCheckboxImage = ({ element, layoutItem }: IPreviewImageChoiceWidgetProps) => {
  const {
    label,
    replacementLabel,
    replacementOptionsLabels,
    dataImage,
    isMainLabelHidden,
    widgetId,
    sectionId,
    isBottomDivider,
    isHighlighted,
    listingOption,
    displayMode,
    isUnitLabelHidden
  } = element;
  const isColumnMode = (displayMode ?? DisplayMode.column) === DisplayMode.column;
  const selectedOptions = useMemo(() => {
    if ('selectedOptions' in element && Array.isArray(element.selectedOptions)) {
      return element.selectedOptions;
    } else if ('selectedOption' in element) {
      return [element.selectedOption];
    }
    return [];
  }, [element]);
  const [isAllImagesHidden, setIsallImagesHidden] = useState(false);

  const renderOptionsHandler = useMemo(() => {
    const checkedOptions = Array.isArray(selectedOptions) ? new Set(selectedOptions) : new Set([selectedOptions]);
    const renderLabelOptions = replacementOptionsLabels?.filter((labelObj: { id: string }) => checkedOptions.has(labelObj.id));
    const allLabelsHidden = renderLabelOptions?.every((option) => option.isLabelHidden);

    if (renderLabelOptions?.every((option) => option.isImageHidden)) {
      setIsallImagesHidden(true);
    }

    if (allLabelsHidden && isAllImagesHidden) {
      return null;
    }

    return renderLabelOptions?.map((labelObj, index) => {
      const id = labelObj.id;
      const isSelected = checkedOptions.has(labelObj.id);
      const articleOption = element?.articles?.find((article) => article.widgetOptionId === labelObj?.id);
      const priceOption = articleOption?.article?.price;
      const renderLabelObj = renderLabelOptions.find((obj) => obj.id === labelObj?.id);
      const originalLabel = renderLabelObj?.labelImage;
      const answerReplacementLabel = renderLabelObj?.replacementLabel;
      const imageSrc = getStorageFileUrl(renderLabelObj?.data?.[0]?.fileUrl ?? '', renderLabelObj?.data?.[0]?.type ?? '');
      const isImageHidden = renderLabelObj?.isImageHidden;
      const isLabelHidden = renderLabelObj?.isLabelHidden;
      const selectedOption = dataImage?.find((option) => option.id === labelObj?.id);
      const numericValue = getNumericValuesWithUnits({ selectedOption, priceOption, isUnitLabelHidden });
      if (selectedOptions.length === 1 && isImageHidden) {
        setIsallImagesHidden(true);
      }
      return (
        <PreviewAnswerWrapper
          index={index}
          isGrid={selectedOptions.length === 1 ? !isImageHidden : !isAllImagesHidden}
          imageSrc={imageSrc}
          key={id}
          listingOption={listingOption}
          displayMode={displayMode}
          answerLabel={renderInputLabel(answerReplacementLabel, originalLabel)}
          isSelected={isSelected}
          isImageHidden={isImageHidden}
          isLabelHidden={isLabelHidden}
          numericValueLabel={numericValue}
          questionLabel={renderInputLabel(replacementLabel, label)}
          isQuestionLabelHidden={!!isMainLabelHidden}
        />
      );
    });
  }, [isAllImagesHidden, dataImage, replacementOptionsLabels, element?.articles, element?.dataImage, selectedOptions]);

  return (
    <PreviewWidgetWrapper
      isBottomDivider={isBottomDivider}
      isHighlighted={isHighlighted}
      widgetId={widgetId}
      sectionId={sectionId}
      layoutItem={layoutItem}
      questionLabel={renderInputLabel(replacementLabel, label)}
      isQuestionLabelHidden={(!!isMainLabelHidden || !isColumnMode) && isAllImagesHidden}
      isGrid={!isAllImagesHidden}
    >
      {renderOptionsHandler}
    </PreviewWidgetWrapper>
  );
};

export default PreviewCheckboxImage;
