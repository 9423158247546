import { Box, Toolbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Breadcrumbs from 'shared/components/@extended/Breadcrumbs';
import Header from 'shared/components/Header';
import navigation from 'shared/components/menu-items';
import useConfig from 'shared/hooks/useConfig';
import { RootStateProps } from 'shared/types/root';
import { openDrawer } from 'store/menu';
import Drawer from './Drawer';
import * as Styles from './MainLayout.styles';

interface IMainLayout {
  isHeaderVisible?: boolean;
  isLeftMenuVisible?: boolean;
  isHeaderPlaceholderVisible?: boolean;
}
const MainLayout = ({ isHeaderVisible = true, isLeftMenuVisible = true, isHeaderPlaceholderVisible }: IMainLayout) => {
  const { container } = useConfig();
  const dispatch = useDispatch();

  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;
  const handleDrawerToggle = () => {
    dispatch(openDrawer({ drawerOpen: !drawerOpen }));
  };

  return (
    <Styles.MainLayoutBoxWrapper>
      {isHeaderVisible && <Header open={drawerOpen} handleDrawerToggle={handleDrawerToggle} withSidebar />}
      {isLeftMenuVisible && <Drawer open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />}
      <Box component="main" sx={{ flexGrow: 1 }}>
        {isHeaderVisible && <Toolbar variant="dense" />}
        {isHeaderPlaceholderVisible && <Toolbar variant="dense" />}
        {container && (
          <Styles.MainLayoutContainerWrapper
            maxWidth={false}
            sx={{
              p: { xs: 0.5, sm: 1, md: 2, lg: 3, xl: 5 },
              maxHeight: 'calc(100dvh - 50px)',
              overflow: 'auto'
            }}
          >
            <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
            <Outlet />
            {/* <Footer /> */}
          </Styles.MainLayoutContainerWrapper>
        )}
        {!container && (
          <Styles.NoContainerBoxWrapper>
            <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
            <Outlet />
            {/* <Footer /> */}
          </Styles.NoContainerBoxWrapper>
        )}
      </Box>
    </Styles.MainLayoutBoxWrapper>
  );
};

export default MainLayout;
