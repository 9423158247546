import { Box, Grid, InputBase, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import EditorClosingText from 'modules/editor/components/EditorClosingText';
import { EEditorType, ValuesEditorType } from 'modules/editor/types/editor';
import { PdfTemplateParameters } from 'modules/settings/types';
import { ChangeEvent, memo } from 'react';
import { useIntl } from 'react-intl';

interface IEditorFooter {
  type: EEditorType;
  parameters: PdfTemplateParameters;
  fontFamily: string;
}
const EditorFooter = ({ parameters, type, fontFamily }: IEditorFooter) => {
  const intl = useIntl();
  const theme = useTheme();

  const { values, setFieldValue } = useFormikContext<ValuesEditorType>();

  const handleChangeFooterBox = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, indexData: number) => {
    const newFooterTexts =
      values?.settingsTemplate?.footerTexts?.map((item, index) => (index === indexData ? { ...item, text: e.target.value } : item)) ?? [];
    setFieldValue('settingsTemplate', { ...values.settingsTemplate, footerTexts: newFooterTexts });
  };
  const footerCount = values?.settingsTemplate?.footerTexts?.length ?? 0;

  return (
    <Box margin="0 40px">
      <EditorClosingText parameters={parameters} type={type} fontFamily={fontFamily} />

      <Grid container spacing={1} wrap="nowrap">
        {values?.settingsTemplate?.footerTexts?.map((footer, index) => (
          <Grid item key={'footer' + footer?.id + 'ind' + index} flexGrow={1}>
            <InputBase
              sx={{
                overflow: 'hidden',
                borderRadius: '6px',
                paddingLeft: '10px',
                marginLeft: '-10px',
                cursor: 'pointer',
                '& textarea': {
                  fontFamily: fontFamily,
                  color: theme.palette.text.secondary,
                  fontSize: '12px',
                  cursor: 'pointer',
                  textAlign: footerCount <= 2 ? 'center' : 'left'
                },
                '&:hover': {
                  backgroundColor: theme.palette.action.hover
                },
                '&:focus-within': {
                  boxShadow: `0 0 0 2px ${theme.palette.primary.light} inset`,
                  backgroundColor: 'transparent',
                  cursor: 'text',
                  '& textarea': {
                    cursor: 'text'
                  }
                }
              }}
              fullWidth
              multiline
              minRows={3}
              maxRows={5}
              value={footer.text}
              onChange={(ev) => handleChangeFooterBox(ev, index)}
              placeholder={intl.formatMessage({ id: 'settings.add-contact-details-here' })}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default memo(EditorFooter);
