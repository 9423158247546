import { Box, TableRow as MuiTableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ITypeOfTable {
  isOptionalTable: boolean;
}
export const TableHeaderRow = styled(MuiTableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '& .MuiTableCell-root': {
    textTransform: 'capitalize'
  },

  '& .Mui-active': {
    color: `${theme.palette.primary.main} !important`,
    '& .MuiTableSortLabel-icon': {
      color: `${theme.palette.primary.main} !important`
    }
  },

  '& .MuiTableCell-head': {
    width: '100px'
  },
  '& .MuiTableCell-head:first-of-type': {
    paddingLeft: '12px !important',
    width: '50px !important'
  },
  '& .MuiTableCell-head:last-of-type': {
    paddingRight: '8px !important',
    width: '50px !important'
  },
  '& .MuiTableSortLabel-root': {
    minWidth: '0px'
  }
}));

export const Wrapper = styled(Box)<ITypeOfTable>(() => ({
  padding: '0',
  position: 'relative'
}));
