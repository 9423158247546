import {
  ArrowLeft20Filled,
  ArrowLeft20Regular,
  ChevronDown20Filled,
  ChevronUp20Filled,
  DocumentPdf20Regular,
  Mail20Regular,
  Settings20Regular
} from '@fluentui/react-icons';
import { alpha, Box, Button, Chip, CircularProgress, IconButton, MenuItem, Select, Stack, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import { EditorType, EEditorType, ValuesEditorType } from 'modules/editor/types/editor';
import { downloadPdfByIdEditor } from 'modules/editor/utils';
import { generateTooltipByButtonSendEmail } from 'modules/invoices/utils/prepareData';
import { ReactNode, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import PromptMessage from 'shared/components/PromptMessage';
import TooltipWrapper from 'shared/components/TooltipWrapper';
import { questionnaireTaxtype } from 'shared/data/questionnaires';
import { useGetDataByRole } from 'shared/hooks/useGetDataByRole';
import { ITenantPayload } from 'shared/types/tenants';
import * as Styles from './EditorHeader.style';

interface IEditorHeader {
  isLoading: boolean;
  tenant?: ITenantPayload;
}

interface IButtonMenu {
  id: number;
  action: () => void;
  icon: ReactNode;
  isDisabled: boolean;
  tooltipTitle: string;
}
interface IEditorHeader {
  type: EditorType;
  title: string;
  children: ReactNode;
  isLoading: boolean;
  onEdit: () => void;
  onSendEmail: () => void;
}

const EditorHeader = ({ type, title, children, isLoading, onEdit, onSendEmail }: IEditorHeader) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const theme = useTheme();
  const [isOpenModalWarning, setIsOpenModalWarning] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { idUser, disableForEventManager } = useGetDataByRole();
  const { values, handleChange, dirty } = useFormikContext<ValuesEditorType>();
  const borderColor = theme.palette.mode === 'dark' ? alpha(theme.palette.grey[900]!, 0.08) : alpha(theme.palette.grey[900]!, 0.1);
  const isInvoice = type === EEditorType.Invoices;

  const handleConfirmGoHome = () => {
    navigate(isInvoice ? '/invoices' : '/requests');
  };

  const handleHideWarning = () => {
    setIsOpenModalWarning(false);
  };

  const handleButtonGoHome = () => {
    if (!dirty) handleConfirmGoHome();
    else setIsOpenModalWarning(true);
  };

  const handleGeneratePdf = () => {
    if (!values?.id) return;
    downloadPdfByIdEditor(values.id, type);
  };

  const buttonsMenu: IButtonMenu[] = [
    {
      id: 1,
      action: onEdit,
      icon: <Settings20Regular />,
      isDisabled: dirty,
      tooltipTitle: dirty
        ? 'invoices.invoice-editor-button-lock'
        : isInvoice
          ? 'invoices.edit-invoice-details'
          : 'requests.edit-offer-details'
    },
    {
      id: 2,
      action: handleGeneratePdf,
      icon: <DocumentPdf20Regular />,
      isDisabled: dirty,
      tooltipTitle: dirty ? 'invoices.invoice-editor-button-lock' : 'invoices.row-options-open-pdf'
    },
    {
      id: 3,
      action: onSendEmail,
      icon: <Mail20Regular />,
      isDisabled: dirty || disableForEventManager,
      tooltipTitle: generateTooltipByButtonSendEmail(disableForEventManager, !!values?.isSentEmail, dirty)
    }
  ];

  const isHideSaveButton = disableForEventManager && values?.data?.eventManager?.id !== idUser;

  return (
    <>
      <Stack
        boxShadow={{
          xs: isCollapsed
            ? 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px'
            : 'rgba(0, 0, 0, 0.3) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
          md: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px'
        }}
        borderBottom={`1px solid ${borderColor}`}
        direction={{ xs: 'column', md: 'row' }}
        gap="5px"
        width="100vw"
        padding="5px"
        justifyContent={{ xs: 'space-between', md: 'space-between' }}
        alignItems={{ xs: 'center', md: 'center' }}
      >
        <Stack
          direction="row"
          gap="5px"
          width={{ xs: '100%', md: 'auto' }}
          justifyContent={{ xs: 'space-between', md: 'flex-start' }}
          alignItems="center"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <Button
            onClick={handleButtonGoHome}
            variant="outlined"
            color="secondary"
            startIcon={<ArrowLeft20Regular />}
            sx={{ display: { xs: 'none', md: 'inherit' } }}
          >
            <FormattedMessage id="settings.go-back" />
          </Button>
          <IconButton onClick={handleButtonGoHome} sx={{ display: { xs: 'block', md: 'none' } }}>
            <ArrowLeft20Filled />
          </IconButton>
          <Stack direction="row" alignItems="center" gap="10px">
            <Styles.BoldText>{title}</Styles.BoldText>
            <Chip size="small" label={values?.number} />
          </Stack>
          <IconButton
            sx={{ display: { xs: 'block', md: 'none' } }}
            color={!isCollapsed ? 'primary' : 'secondary'}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? <ChevronDown20Filled /> : <ChevronUp20Filled />}
          </IconButton>
        </Stack>

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          gap="5px"
          width={{ xs: '100%', md: 'auto' }}
          justifyContent={{ xs: 'space-between', md: 'flex-start' }}
          alignItems="center"
          display={{ xs: isCollapsed ? 'none' : 'flex', md: 'flex' }}
        >
          <Select
            sx={{ width: { xs: '100%', sm: 'auto' } }}
            value={values.taxType ?? null}
            name="taxType"
            onChange={handleChange}
            placeholder={intl.formatMessage({ id: 'events.select-here' })}
            inputProps={{ placeholder: intl.formatMessage({ id: 'events.select-here' }) }}
          >
            {questionnaireTaxtype.map((item) => (
              <MenuItem key={`select-taxtype-item-${item.formatId}`} value={item.value}>
                <FormattedMessage id={item.formatId} />
              </MenuItem>
            ))}
          </Select>
          {children}
          {buttonsMenu.map((button) => (
            <>
              <TooltipWrapper title={button?.tooltipTitle || ''}>
                <Button
                  variant="outlined"
                  sx={{
                    minHeight: '36px',
                    gap: '5px',
                    width: { xs: '100%', sm: 'auto' }
                  }}
                  disabled={button.isDisabled}
                  onClick={button.action}
                  color="secondary"
                >
                  {button.icon}
                  {button.tooltipTitle && (
                    <Box display={{ xs: 'block', sm: 'none' }}>{intl.formatMessage({ id: button.tooltipTitle })}</Box>
                  )}
                </Button>
              </TooltipWrapper>
            </>
          ))}
          {!isHideSaveButton && (
            <Button
              fullWidth
              type="submit"
              variant="contained"
              disabled={isLoading}
              startIcon={isLoading && <CircularProgress size={'20px'} />}
            >
              <FormattedMessage id="settings.save-changes" />
            </Button>
          )}
        </Stack>
      </Stack>
      <PromptMessage
        isDirtyForm={dirty}
        handleConfirm={handleConfirmGoHome}
        isOpenModalWarning={isOpenModalWarning}
        handleCloseModal={handleHideWarning}
      />
    </>
  );
};

export default EditorHeader;
