import { useGetCurrentTenant } from 'api/hooks/tenant/useGetCurrentTenant';
import { useFormikContext } from 'formik';
import EditorDetails, { IEditorDetails } from 'modules/editor/components/EditorDetails';
import { EEditorType } from 'modules/editor/types/editor';
import { useFormatCurrentDate } from 'shared/hooks/regionalSettings';
import { IOfferEditor } from 'shared/types/offer';
import { IVenue } from 'shared/types/venue';

const OfferEditorDetails = ({ fontFamily, venue }: { fontFamily: string; venue?: IVenue }) => {
  const { values } = useFormikContext<IOfferEditor>();
  const { data: tenant } = useGetCurrentTenant();
  const templateLogo =
    typeof values?.settingsTemplate?.logoImagePath === 'string'
      ? values?.settingsTemplate?.logoImagePath
      : tenant?.loginViewSetting?.loginLogoUrl;
  const formatFn = useFormatCurrentDate();

  const dataAdditional: IEditorDetails['data'] = [
    { intlId: 'offer.modal-field-serial-number', value: values.number ?? '' },
    { intlId: 'offer.date-of-offer', value: formatFn(values.baseDates?.offerDate as string) },

    {
      intlId: values.baseDates?.eventDate ? 'offer.modal-field-event-date' : 'offer.modal-field-event-date',
      value: values.baseDates?.eventDate
        ? formatFn(values.baseDates?.eventDate as string)
        : values.baseDates?.requestedEventDate
          ? formatFn(values.baseDates?.requestedEventDate as string)
          : null
    },
    { intlId: 'offer.modal-field-offer-valid-until', value: formatFn(values.baseDates?.validUntil as string) }
  ].filter((date) => !!date.value);

  return (
    <EditorDetails
      venue={venue}
      key={JSON.stringify(values)}
      type={EEditorType.Offers}
      templateLogo={templateLogo}
      fontFamily={fontFamily}
      data={dataAdditional}
    />
  );
};

export default OfferEditorDetails;
