import { useFormikContext } from 'formik';
import EditorDetails, { IEditorDetails } from 'modules/editor/components/EditorDetails';
import { EEditorType } from 'modules/editor/types/editor';
import { useCurrentDateFormatter } from 'shared/hooks/regionalSettings';
import { IInvoiceEditor } from 'shared/types/invoice';
import { IVenue } from 'shared/types/venue';

const InvoiceEditorDetails = ({ fontFamily, venue }: { fontFamily: string; venue?: IVenue }) => {
  const { values } = useFormikContext<IInvoiceEditor>();
  const templateLogo = typeof values?.settingsTemplate?.logoImagePath === 'string' ? values?.settingsTemplate?.logoImagePath : '';
  const dataAdditional: IEditorDetails['data'] = [
    { intlId: 'invoices.invoice-editor-invoice-number', value: values.number ?? '' },
    { intlId: 'invoices.invoice-editor-date-of-invoice', value: useCurrentDateFormatter(values.baseDates?.invoiceDate as string) },
    { intlId: 'invoices.invoice-editor-performance-date', value: useCurrentDateFormatter(values.baseDates?.performanceDate as string) }
  ];

  return (
    <EditorDetails type={EEditorType.Invoices} templateLogo={templateLogo} fontFamily={fontFamily} data={dataAdditional} venue={venue} />
  );
};

export default InvoiceEditorDetails;
