import {
  Box as MuiBox,
  IconButton as MuiIconButton,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  Typography as MuiTypography
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const TableCellNestedRow = styled(MuiTableCell)(() => ({
  '.MuiTableRow-root &.MuiTableCell-root:first-of-type': {
    // padding: 12
  },
  '.MuiTableRow-root &.MuiTableCell-root:last-of-type': {
    padding: 12
  }
}));

interface ITableRowProps {
  fontFamily: string;
}
export const TableRow = styled(MuiTableRow)<ITableRowProps>(({ theme, fontFamily }) => ({
  verticalAlign: 'top',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '8px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  fontFamily: `${fontFamily} !important`,
  '& .MuiTypography-root': {
    fontFamily: `${fontFamily} !important`
  },
  '& textarea': {
    fontFamily: `${fontFamily} !important`
  },
  '& input': {
    fontFamily: `${fontFamily} !important`
  },
  '&:last-of-type': {
    borderBottom: 'none'
  },
  '& .MuiTableCell-root:first-of-type': {
    paddingLeft: '5px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    with: 'min-content',
    minHeight: '45px !important'
  },
  '& .dragHandle': {
    display: 'none',
    textAlign: 'left'
  },
  '& .index': {
    display: 'block'
  },
  '&:hover .dragHandle': {
    display: 'block'
  },
  '&:hover .index': {
    display: 'none'
  },
  '&:active': {
    borderBottom: `none`,
    '& .MuiTableCell-root:first-of-type': {
      display: 'table-cell',
      width: '48px'
    },
    '& .dragHandle': {
      display: 'block',
      margin: '7px 10px'
    },
    '& .index': {
      display: 'none'
    },
    zIndex: '9999 !important',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'
  },
  '& .MuiTableCell-root': {
    padding: '6px 3px',
    borderBottom: 'none'
  },

  '& .MuiTableCell-root:last-of-type': {
    paddingRight: '5px !important',
    '& .MuiIconButton-root': {
      margin: '2px'
    }
  }
}));

export const Typography = styled(MuiTypography)(() => ({
  paddingTop: '10px'
}));

export const Box = styled(MuiBox)(() => ({
  display: 'flex'
}));

export const IconButton = styled(MuiIconButton)(() => ({
  // marginTop: '2px',
  // marginLeft: '4px'
}));

interface INestedCellBoxProps {
  isMarginTopUsed: boolean;
}

export const NestedCellBox = styled(MuiBox)<INestedCellBoxProps>(({ isMarginTopUsed }) => ({
  marginTop: isMarginTopUsed ? '4px' : undefined,
  width: '100%'
}));
