import { Card, useTheme } from '@mui/material';
import { useEditOffer } from 'api/hooks/offers/useEditOffer';
import { useGetOfferById } from 'api/hooks/offers/useGetOfferById';
import { useGetOfferItemsSelected } from 'api/hooks/offers/useGetOfferItemsSelected';
import { useUpdateOfferItems } from 'api/hooks/offers/useUpdateOfferItems';
import { useGetRequestById } from 'api/hooks/requests/useGetRequestById';
import { Form, FormikProvider, useFormik } from 'formik';
import EditorFooter from 'modules/editor/components/EditorFooter';
import { EEditorType } from 'modules/editor/types/editor';
import { prepareDataForItemsRequest } from 'modules/invoices/utils/prepareData';
import GreetingTextImportButtons from 'modules/offers/components/GreetingTextImportButtons';
import OfferEditorDetails from 'modules/offers/components/OfferEditorDetails';
import OfferEditorHeader from 'modules/offers/components/OfferEditorHeader';
import OfferEditorTable from 'modules/offers/components/OfferEditorTable';
import { prepareOfferEditorForSave, prepareOfferEditorToForm } from 'modules/offers/utils/prepareData';
import { FONT_FAMILY_OPTIONS } from 'modules/settings/constants';
import { PdfTemplateParameters } from 'modules/settings/types';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageTitle from 'shared/components/PageTitle';
import { useFormatCurrentDate } from 'shared/hooks/regionalSettings';
import { useInfoMessage } from 'shared/hooks/useInfoMessage';
import { IOfferEditor, IOfferForSave } from 'shared/types/offer';
import { textError } from 'shared/utils/infoText';
import { selectPaginationRequests } from 'store/pagination/pagination.selectors';

const OfferEditor = () => {
  const [fontFamily, setFontFamily] = useState<string>('');
  const intl = useIntl();
  const theme = useTheme();
  const formatFn = useFormatCurrentDate();
  const { offerId } = useParams();
  const { successMessage, errorMessage } = useInfoMessage();
  const paginationRequests = useSelector(selectPaginationRequests);
  const offerIdNumber = offerId ? Number(offerId) : undefined;

  const { data: offerItemsSelected } = useGetOfferItemsSelected(offerIdNumber);
  const { data: offerEditorData, isLoading: isEditorLoading } = useGetOfferById({ offerId: Number(offerId) });
  const { data: reguestData } = useGetRequestById({ id: offerEditorData?.request?.id?.toString() });
  const { mutateAsync: mutateEditOffer, isLoading: isLoadingEdit } = useEditOffer({
    id: offerIdNumber,
    paginationRequests,
    options: {
      onError(error) {
        errorMessage(textError(error));
      }
    }
  });
  const { mutateAsync: saveItemsAsync, isLoading: isOfferItemsSaving } = useUpdateOfferItems({});
  const defaultInitData = useMemo(
    () => prepareOfferEditorToForm({ data: offerEditorData, articles: offerItemsSelected }),
    [offerEditorData, offerItemsSelected]
  );

  const formik = useFormik<IOfferEditor>({
    initialValues: defaultInitData,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (!values?.id) {
        return;
      }
      const dataForSaveOffer: IOfferForSave = prepareOfferEditorForSave(values);
      await mutateEditOffer(dataForSaveOffer);
      const itemsValues = prepareDataForItemsRequest(values.articles);
      const itemsValuesOptional = prepareDataForItemsRequest(values.articlesOptional);
      const finalItems = [...itemsValues, ...itemsValuesOptional];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      await saveItemsAsync(
        { id: values.id!, items: finalItems },
        {
          onSuccess: () => {
            successMessage(intl.formatMessage({ id: 'offer.editor-update-success' }));
          },
          onError(error) {
            errorMessage(textError(error));
          }
        }
      );
    }
  });

  const { resetForm, values } = formik;

  useEffect(() => {
    const settingsTemplate = values.settingsTemplate as unknown as { font: string; invoiceFont: string };
    const fontFamily = settingsTemplate?.invoiceFont ?? values.settingsTemplate?.font ?? theme.typography.fontFamily;
    const readableFont =
      FONT_FAMILY_OPTIONS.find((font) => font.replace(/\s+/g, '').toLowerCase() === fontFamily?.toLowerCase()) ??
      theme.typography.fontFamily ??
      '';
    setFontFamily(readableFont);
  }, [values.settingsTemplate, theme.typography.fontFamily]);

  useEffect(() => {
    if (!offerEditorData || !offerItemsSelected) return;
    resetForm({ values: prepareOfferEditorToForm({ data: offerEditorData, articles: offerItemsSelected }) });
  }, [resetForm, offerEditorData, offerItemsSelected]);

  const getAnonymousLink = () => {
    if (!reguestData?.anonymousLinkPrefix) {
      console.error('prefix is not exist');
      return '';
    }
    const url = `${window.location.origin}/requests/anonymous/${reguestData?.anonymousLinkPrefix}`;
    return url;
  };

  const anonymousLink = useMemo(() => getAnonymousLink(), [offerEditorData]);

  const paramsInit: PdfTemplateParameters = {
    go_to_request: '',
    offer_date: '',
    requested_date: '',
    recipient_name: '',
    recipient_surname: '',
    recipient_partner_name: '',
    recipient_partner_surname: '',
    event_manager_name: '',
    event_manager_surname: '',
    greeting_formal: ''
  };

  const [parameters, setParameters] = useState<PdfTemplateParameters>(paramsInit);

  useMemo(() => {
    const params: PdfTemplateParameters = {
      go_to_request: anonymousLink,
      offer_date: formatFn(values.baseDates?.offerDate?.toString() ?? ' '),
      requested_date: offerEditorData?.event?.eventDate
        ? formatFn(offerEditorData?.event?.eventDate?.toString() ?? '')
        : formatFn(offerEditorData?.request?.requestedEventDate?.toString()),
      recipient_name: values.recipient?.name ?? '',
      recipient_surname: values.recipient?.surname ?? '',
      recipient_partner_name: values.recipient?.details?.partner?.name ?? '',
      recipient_partner_surname: values.recipient?.details?.partner?.surname ?? '',
      event_manager_name: values.data?.eventManager?.name ?? values.data?.requestManager?.name ?? '',
      event_manager_surname: values.data?.eventManager?.surname ?? values.data?.requestManager?.surname ?? '',
      greeting_formal:
        values.recipient?.gender === 'Female'
          ? `Sehr geehrte Frau ${values.recipient?.surname ?? ''},`
          : `Sehr geehrter Herr ${values.recipient?.surname ?? ''},`,
      greeting_partner_formal: !!values.recipient?.details?.partner?.name
        ? values.recipient?.gender === 'Female'
          ? `Sehr geehrte Frau ${values.recipient?.details?.partner?.surname ?? ''},`
          : `Sehr geehrter Herr ${values.recipient?.details?.partner?.surname ?? ''},`
        : '',
      greeting_informal:
        values.recipient?.gender === 'Female' ? `Liebe ${values.recipient?.name ?? ''},` : `Lieber ${values.recipient?.name ?? ''},`,
      greeting_partner_informal: !!values.recipient?.details?.partner?.name
        ? values.recipient?.gender === 'Female'
          ? `Liebe ${values.recipient?.details?.partner?.name ?? ''},`
          : `Lieber ${values.recipient?.details?.partner?.name ?? ''},`
        : ''
    };
    setParameters(params);
  }, [
    values.baseDates?.requestedEventDate,
    values.baseDates?.offerDate,
    values.recipient,
    values.data?.eventManager,
    values.data?.requestManager,
    anonymousLink,
    offerEditorData?.event?.eventDate,
    offerEditorData?.request?.requestedEventDate
  ]);

  return (
    <>
      <PageTitle title={offerEditorData?.offerNumber} />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
          <OfferEditorHeader
            isLoading={isEditorLoading || isLoadingEdit || isOfferItemsSaving}
            hasEvent={!!offerEditorData?.event?.id}
            hasRequest={!!offerEditorData?.request?.id}
            eventDate={offerEditorData?.event?.eventDate ? new Date(offerEditorData?.event?.eventDate) : undefined}
          />
          <Card
            sx={{
              padding: '60px 40px 30px 40px',
              boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
              width: { xs: '100%', lg: '80vw', xl: '70vw' },
              maxWidth: '1200px',
              minWidth: '820px',
              margin: '0 auto',
              marginBottom: 10
            }}
          >
            <OfferEditorDetails fontFamily={fontFamily} venue={reguestData?.venue} />
            <GreetingTextImportButtons parameters={parameters} fontFamily={fontFamily} />
            <OfferEditorTable parameters={parameters} type={EEditorType.Offers} fontFamily={fontFamily} />
            <EditorFooter type={EEditorType.Offers} parameters={parameters} fontFamily={fontFamily} />
          </Card>
        </Form>
      </FormikProvider>
    </>
  );
};

export default OfferEditor;
