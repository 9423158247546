import { Delete16Regular, ReOrderDotsVertical20Regular } from '@fluentui/react-icons';
import { Draggable } from '@hello-pangea/dnd';
import { Box, IconButton, TableCell, Typography } from '@mui/material';
import { FieldArrayRenderProps } from 'formik';
import { bruttoArticle } from 'modules/articles/utils/pricingCalc';
import { EEditorArticleItemType, IEditorArticle, IEditorArticleItem } from 'modules/editor/types/editor';
import {
  calculateAmount,
  calculateBruttoIfChangedNetto,
  calculateNettoIfChangedBrutto,
  getNameOfPriceField,
  getNameOfTotalPriceField
} from 'modules/invoices/utils/calculateHelpers';
import FormatPrice from 'modules/pricing-sidebar/components/FormatPrice';
import { TO_FIXED_FOR_PRICE } from 'modules/pricing-sidebar/constants/inputs';
import { QuestionnaireTaxType } from 'modules/questionnaires/types';
import { ComponentType, memo } from 'react';
import { useIntl } from 'react-intl';
import NumericInput from 'shared/components/NumberInputField';
import DebouncedTextField from 'shared/components/TextFieldDebounced';
import { EArticlesValueIOfferEdit, IOfferArticleBaseAndOptionalType, IOfferEditor } from 'shared/types/offer';
import { skipEnterInTextField } from 'shared/utils/form';
import * as Styles from './TableDynamicRow.styles';

interface ICustomInputHanlerParams {
  name: string;
  value: string | number;
  article: IOfferArticleBaseAndOptionalType;
  articleIndex: number;
  articleItemIndex: number;
  taxType: QuestionnaireTaxType;
}

interface ITableDynamicRow {
  currencySign: string | undefined;
  nameFields: EArticlesValueIOfferEdit;
  fontFamily: string;
}

const TableDynamicRow: ComponentType<(FieldArrayRenderProps & ITableDynamicRow) | void> = (props) => {
  const { formatMessage } = useIntl();

  if (!props?.push) {
    return null;
  }
  const {
    form: { handleChange, setFieldValue },
    currencySign,
    nameFields,
    fontFamily,
    ...arrayHelpers
  } = props;
  const values: IOfferEditor = props.form.values as IOfferEditor;

  const customeInputHandler = ({ name, value, article, articleItemIndex, taxType, articleIndex }: ICustomInputHanlerParams) => {
    const item = article.priceItems[articleItemIndex];
    const newBrutto = calculateBruttoIfChangedNetto(name, value, taxType, article?.tax);
    const newNetto = calculateNettoIfChangedBrutto(name, value, taxType, article?.tax);
    const updatedItem = {
      ...item,
      [name]: value,
      ...newNetto,
      ...newBrutto
    };
    const newArticleItems = article.priceItems.map((articleItem, index) => (index === articleItemIndex ? updatedItem : articleItem));
    const newArticle: IOfferArticleBaseAndOptionalType = {
      ...article,
      totalNet: calculateAmount(newArticleItems, QuestionnaireTaxType.Net),
      totalGross: calculateAmount(newArticleItems, QuestionnaireTaxType.Gross),
      priceItems: newArticleItems
    };
    setFieldValue(`${nameFields}.${articleIndex}`, newArticle);
  };

  const numberInputTaxHandler = ({
    value,
    article,
    articleIndex
  }: {
    value: string | number;
    article?: IOfferArticleBaseAndOptionalType;
    articleIndex: number;
  }) => {
    const newPriceItemsArticle =
      article?.priceItems?.map((item) => ({
        ...item,
        priceBrutto: bruttoArticle(item.priceNetto ?? 0, !!value ? Number(value) : 0)
      })) ?? [];
    setFieldValue(`${props.nameFields}.${articleIndex}`, {
      ...article,
      priceItems: newPriceItemsArticle,
      tax: value,
      totalNet: calculateAmount(newPriceItemsArticle, QuestionnaireTaxType.Net),
      totalGross: calculateAmount(newPriceItemsArticle, QuestionnaireTaxType.Gross)
    } as IEditorArticle);
  };

  const priceFieldName = getNameOfPriceField(values.taxType);
  const totalPriceFieldName = getNameOfTotalPriceField(values.taxType);

  return (
    <>
      {values?.[nameFields]?.map((article, index) => (
        <Draggable key={article.id ?? article._innerId} draggableId={article.id ? `${article.id}` : `${article._innerId}`} index={index}>
          {(provided) => (
            <Styles.TableRow
              fontFamily={fontFamily}
              key={article.id ?? article._innerId}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <TableCell sx={{ cursor: 'grab !important' }} className="first-cell">
                <ReOrderDotsVertical20Regular className="dragHandle" />
                <Typography textAlign="right" sx={{ width: '20px' }} className="index">
                  {index + 1}
                </Typography>
              </TableCell>
              <TableCell width="600px">
                <DebouncedTextField
                  onKeyDown={skipEnterInTextField}
                  fullWidth
                  multiline
                  name={`${nameFields}.${index}.name`}
                  value={values[nameFields as EArticlesValueIOfferEdit]?.[index]?.name}
                  onChange={handleChange}
                  placeholder={formatMessage({ id: 'invoices.invoice-editor-description-placeholder' })}
                />
              </TableCell>
              <TableCell>
                <NumericInput
                  width="100px"
                  isDecimal
                  maxDecimalDigits={2}
                  showDecimalZeros
                  textAlign="right"
                  name={`${nameFields}.${index}.tax`}
                  value={values[nameFields as EArticlesValueIOfferEdit]?.[index]?.tax ?? 0}
                  onHandleInputChange={(value) =>
                    numberInputTaxHandler({ value, article: article as IOfferArticleBaseAndOptionalType, articleIndex: index })
                  }
                  startAdornment={''}
                />
              </TableCell>
              <TableCell>
                {article?.priceItems?.map(
                  (item: IEditorArticleItem, itemIndex: number) =>
                    item?.type !== EEditorArticleItemType.SecondUnit && (
                      <Box key={item.id ?? itemIndex} mt={!!itemIndex ? '4px' : 'none'}>
                        <NumericInput
                          value={values.taxType === QuestionnaireTaxType.Net ? item?.priceNetto : (item?.priceBrutto ?? 0)}
                          width="110px"
                          textAlign="right"
                          showDecimalZeros
                          name={priceFieldName}
                          maxDecimalDigits={TO_FIXED_FOR_PRICE}
                          onHandleInputChange={(value) =>
                            customeInputHandler({
                              value,
                              name: priceFieldName,
                              article: article as IOfferArticleBaseAndOptionalType,
                              articleIndex: index,
                              articleItemIndex: itemIndex,
                              taxType: values.taxType
                            })
                          }
                          isCurrency
                          isDecimal
                          hideAdornment
                        />
                      </Box>
                    )
                )}
              </TableCell>
              <TableCell width="300px">
                {article?.priceItems?.map((item: any, itemIndex: number) => (
                  <Box key={item.id ?? itemIndex} mt={!!itemIndex ? '4px' : 'none'}>
                    <DebouncedTextField
                      onKeyDown={skipEnterInTextField}
                      fullWidth
                      onChange={handleChange}
                      name={`${nameFields}.${index}.priceItems.${itemIndex}.unit`}
                      value={item.unit}
                      placeholder={formatMessage({ id: 'invoices.invoice-editor-unit-placeholder' })}
                    />
                  </Box>
                ))}
              </TableCell>
              <TableCell>
                {article?.priceItems?.map((item: any, itemIndex: number) => (
                  <Box key={item.id ?? itemIndex} mt={!!itemIndex ? '4px' : 'none'}>
                    <NumericInput
                      width="120px"
                      showDecimalZeros
                      isDecimal
                      maxDecimalDigits={2}
                      textAlign="right"
                      name="count"
                      value={item?.count}
                      allowNegative
                      onHandleInputChange={(value) =>
                        customeInputHandler({
                          value,
                          name: 'count',
                          article: article as IOfferArticleBaseAndOptionalType,
                          articleIndex: index,
                          articleItemIndex: itemIndex,
                          taxType: values.taxType
                        })
                      }
                    />
                  </Box>
                ))}
              </TableCell>
              <TableCell align="right" sx={{ minWidth: 130 }}>
                <Typography margin="7px">
                  <FormatPrice value={article[totalPriceFieldName] ?? 0} isHideSymbol />
                </Typography>
              </TableCell>
              <TableCell>
                <IconButton size="small" onClick={arrayHelpers.handleRemove(index)} color="error">
                  <Delete16Regular />
                </IconButton>
              </TableCell>
            </Styles.TableRow>
          )}
        </Draggable>
      ))}
    </>
  );
};

export default memo(TableDynamicRow);
