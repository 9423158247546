import { AddCircle16Regular } from '@fluentui/react-icons';
import { Button, Grid, InputLabel, Stack, TextField } from '@mui/material';
import { FieldArrayRenderProps } from 'formik';
import { ComponentType } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PhoneNumberInput from 'shared/components/PhoneNumberInput/PhoneNumberInput';
import { IAdditionalContacts, IUser } from 'shared/types/user';

const key = 'additionalContacts';

const DynamicEmailAndPhone: ComponentType<FieldArrayRenderProps | void> = (props) => {
  const intl = useIntl();
  if (!props?.push) {
    return null;
  }
  const {
    form: { handleChange, handleBlur, setFieldValue },
    ...arrayHelpers
  } = props;
  const values = props.form.values as { [key]: IUser['additionalContacts'] };

  const handleChangePhoneNumber = (name: string, value: string) => {
    setFieldValue(name, value);
  };

  return (
    <>
      {values?.additionalContacts.map((additional, index) => (
        <Grid key={index} container spacing={1} sx={{ pb: 1 }}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={1.25}>
              <InputLabel htmlFor={`${key}[${index}].email`}>
                <FormattedMessage id="users.email-address" />
              </InputLabel>
              <TextField
                type="email"
                fullWidth
                value={additional.email}
                name={`${key}[${index}].email`}
                id={`${key}[${index}].email`}
                placeholder={intl.formatMessage({ id: 'events.customer-email-com' })}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack spacing={1.25}>
              <InputLabel htmlFor={`${key}[${index}].phoneNumber`}>
                <FormattedMessage id="users.phone-number" />
              </InputLabel>
              <PhoneNumberInput
                value={additional.phoneNumber ?? ''}
                name={`${key}[${index}].phoneNumber`}
                id={`${key}[${index}].phoneNumber`}
                placeholder={intl.formatMessage({ id: 'users.enter-here' })}
                onBlur={handleBlur}
                onChange={(value) => handleChangePhoneNumber(`${key}[${index}].phoneNumber`, value)}
              />
            </Stack>
          </Grid>
        </Grid>
      ))}
      <Grid container spacing={1} sx={{ pb: 2.5 }}>
        <Grid item xs={12}>
          <Button
            size="small"
            sx={{ borderRadius: '60px' }}
            variant="outlined"
            color="secondary"
            type="button"
            startIcon={<AddCircle16Regular />}
            onClick={() => {
              arrayHelpers.push({ email: '', phoneNumber: '' } as IAdditionalContacts);
            }}
          >
            <FormattedMessage id="users.add-additional-email-phone-number" />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default DynamicEmailAndPhone;
