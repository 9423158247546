import { Stack } from '@mui/material';
import 'cropperjs/dist/cropper.css';
import { IMAGE_FIT_TYPE } from 'modules/builder/types/imageWidgetsTypes';
import ImageContainer from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanImageChoiceWithReplacementWidget/ImageContainer';
import { ImageChoiceWidgetBox } from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanImageChoiceWithReplacementWidget/OrganisationPlanSingleImageChoiceWidget.styles';
import { ORGANISATION_PLAN_WIDGET_TYPES } from 'modules/organisation-plan-templates/types';
import { IOrganisationPlanChoiceOptionReplacementLabels } from 'modules/organisation-plan-templates/types/organisationPlanChoiceWidgetType';
import { IQuestionnaireImageItemDataSingleWidget } from 'modules/questionnaires/types/questionnaries';
import { useEffect, useState } from 'react';
import ReplacementTextHeading from 'shared/components/ReplacementText/Heading';
import { dispatch } from 'store';
import { updateOrganisationPlanWidget } from 'store/organisation-plan-template/organisation-plan-template.slice';

interface IOrganisationPlanImageChoiceOptionProps {
  data: IQuestionnaireImageItemDataSingleWidget[] | null;
  multiple: boolean;
  isSingleChoice: boolean;
  id: string;
  typeImage: IMAGE_FIT_TYPE;
  typeWidget: string;
  replacementOptionsLabels?: IOrganisationPlanChoiceOptionReplacementLabels[];
}
const OrganisationPlanImageChoiceOption = ({
  data,
  isSingleChoice,
  typeImage,
  id,
  replacementOptionsLabels
}: IOrganisationPlanImageChoiceOptionProps) => {
  const [replacedOptionLabels, setReplacedOptionLabels] = useState<IOrganisationPlanChoiceOptionReplacementLabels[] | undefined>(undefined);
  useEffect(() => {
    if (replacementOptionsLabels) {
      setReplacedOptionLabels(replacementOptionsLabels);
    } else if (data?.length) {
      setReplacedOptionLabels(
        data.map((option) => ({
          id: option.id,
          replacementLabel: option.labelImage,
          isLabelHidden: false,
          isImageHidden: false,
          label: option.labelImage,
          labelImage: option.labelImage,
          data: option.data
        })) as IOrganisationPlanChoiceOptionReplacementLabels[]
      );
    }
  }, [replacementOptionsLabels, data]);

  useEffect(() => {
    if (replacedOptionLabels) {
      dispatch(
        updateOrganisationPlanWidget({
          widgetId: id,
          widget: {
            type: isSingleChoice ? ORGANISATION_PLAN_WIDGET_TYPES.singleImageChoice : ORGANISATION_PLAN_WIDGET_TYPES.multipleImageChoice,
            replacementOptionsLabels: replacedOptionLabels
          }
        })
      );
    }
  }, [id, isSingleChoice, replacedOptionLabels]);

  const changeOptionVariablesHandler = ({
    option,
    replacementLabel,
    isLabelHidden,
    isImageHidden
  }: {
    option: IQuestionnaireImageItemDataSingleWidget;
    replacementLabel?: string;
    isLabelHidden?: boolean;
    isImageHidden?: boolean;
  }) => {
    if (data?.length) {
      const currentReplacementOptions = (replacedOptionLabels || data) as IOrganisationPlanChoiceOptionReplacementLabels[];
      const updatedReplacementOptionsLabels = currentReplacementOptions?.map((optionLabel) => {
        if (optionLabel.id === option.id) {
          return {
            ...optionLabel,
            replacementLabel: replacementLabel || optionLabel.replacementLabel,
            isLabelHidden:
              typeof isLabelHidden !== 'undefined' && typeof isLabelHidden === 'boolean' ? isLabelHidden : optionLabel.isLabelHidden,
            isImageHidden:
              typeof isImageHidden !== 'undefined' && typeof isImageHidden === 'boolean' ? isImageHidden : optionLabel.isImageHidden
          };
        }
        return optionLabel;
      });
      if (updatedReplacementOptionsLabels?.length) {
        setReplacedOptionLabels(updatedReplacementOptionsLabels);
      }
    }
  };

  const handleOptionReplacementLabelChange = (option: IQuestionnaireImageItemDataSingleWidget, isMainLiableOpen?: boolean) => {
    if (data?.length) {
      const currentReplacementOptions = (replacedOptionLabels || data) as IOrganisationPlanChoiceOptionReplacementLabels[];
      const updatedReplacementOptionsLabels = currentReplacementOptions?.map((optionLabel) => {
        if (optionLabel.id === option.id) {
          return {
            ...optionLabel,
            replacementLabel: isMainLiableOpen ? '' : null
          };
        }
        return optionLabel;
      });
      if (updatedReplacementOptionsLabels?.length) {
        setReplacedOptionLabels(updatedReplacementOptionsLabels);
      }
    }
  };

  return (
    <ImageChoiceWidgetBox>
      {data?.map((option) => {
        return (
          <Stack width="100%" gap="5px" key={option.id}>
            <ImageContainer
              value={option.data}
              typeImage={typeImage}
              isImageHidden={replacementOptionsLabels?.find((i) => i.id === option.id)?.isImageHidden}
              onImageHiddenChange={(val) => changeOptionVariablesHandler({ option: option, isImageHidden: val })}
            />
            <ReplacementTextHeading
              onChange={(event) => changeOptionVariablesHandler({ option: option, replacementLabel: event.target.value })}
              value={replacementOptionsLabels?.find((i) => i.id === option.id)?.replacementLabel}
              content={option.labelImage}
              isLabelHiddenValue={replacementOptionsLabels?.find((i) => i.id === option.id)?.isLabelHidden}
              onLabelHiddenChange={(val) => changeOptionVariablesHandler({ option: option, isLabelHidden: val })}
              onReplacementLabelHiddenChange={() => changeOptionVariablesHandler({ option: option, replacementLabel: '' })}
              isHeadingReplacementOpen={(value) => handleOptionReplacementLabelChange(option, value)}
            />
          </Stack>
        );
      })}
    </ImageChoiceWidgetBox>
  );
};

export default OrganisationPlanImageChoiceOption;
