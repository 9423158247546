import { FormattedMessage, useIntl } from 'react-intl';
import { Box, FormHelperText, Grid, InputLabel, Stack, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { IUserFormData } from 'shared/types/user';
import PhoneNumberInput from 'shared/components/PhoneNumberInput/PhoneNumberInput';

const CreateCustomerFormEmailPhoneInfo = () => {
  const intl = useIntl();
  const { values, errors, touched, handleBlur, handleChange, setFieldValue } = useFormikContext<IUserFormData>();

  const handleChangePhoneNumber = (value: string) => {
    setFieldValue('phoneNumber', value);
  };

  return (
    <Box sx={{ p: '20px 0' }}>
      <Grid container spacing={1} sx={{ pb: 2.5 }}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={1.25}>
            <InputLabel htmlFor="user-email">
              <FormattedMessage id="users.email-address" />
            </InputLabel>
            <TextField
              type="email"
              fullWidth
              value={values.email}
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              id="user-email"
              placeholder={intl.formatMessage({ id: 'events.customer-email-com' })}
            />
          </Stack>
          {touched.email && errors.email && (
            <FormHelperText error id="user-email-helper">
              {errors.email}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack spacing={1.25}>
            <InputLabel htmlFor="user-phone">
              <FormattedMessage id="users.phone-number" />
            </InputLabel>
            <PhoneNumberInput
              value={values.phoneNumber ?? ''}
              name="phoneNumber"
              id="user-phone"
              placeholder={intl.formatMessage({ id: 'users.enter-here' })}
              onBlur={handleBlur}
              onChange={handleChangePhoneNumber}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateCustomerFormEmailPhoneInfo;
