import { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import * as Styles from './Input.styles';

interface IReplacementTextInput {
  value?: string | null;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  isQuestion?: boolean;
}
const ReplacementTextInput = ({ value, onChange, isQuestion = false }: IReplacementTextInput) => {
  const intl = useIntl();
  const [internalValue, setInternalValue] = useState(value ?? '');

  useEffect(() => {
    setInternalValue(value ?? '');
  }, [value]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setInternalValue(event.target.value);
      onChange?.(event);
    },
    [onChange]
  );

  return (
    <Styles.ReplacementInputField
      isQuestion={isQuestion}
      placeholder={intl.formatMessage({ id: 'organisation.replacement' })}
      onChange={handleChange}
      value={internalValue}
    />
  );
};

export default memo(ReplacementTextInput);
