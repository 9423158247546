import { Copy20Regular } from '@fluentui/react-icons';
import { Box, Button, Stack, useTheme } from '@mui/material';
import debounce from 'lodash/debounce';
import { EEditorType } from 'modules/editor/types/editor';
import { QuestionnairePageType } from 'modules/questionnaires/types/questionnaries';
import { PDF_INVOICE_TEXT_VARIABLES, PDF_OFFER_TEXT_VARIABLES } from 'modules/settings/constants';
import { EmailTemplatePageType, PdfTemplateParameters } from 'modules/settings/types';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import DraftTextEditor from './DraftTextEditor';
import PreviewHtmlText from './PreviewHtmlText';

interface IEditorAndPreviewProps {
  editorType: QuestionnairePageType | EmailTemplatePageType | 'PdfTemplate';
  htmlText: string;
  parameters?: PdfTemplateParameters;
  defaultFont?: string;
  type: 'greetingText' | 'closingText' | 'optionalText';
  entityType: EEditorType;
  onChange: (newValue: string) => void;
}

const EditorAndPreview = (props: IEditorAndPreviewProps) => {
  const { editorType, htmlText, parameters, defaultFont, type, entityType, onChange } = props;
  const [isMount, setIsMount] = useState<boolean>(false);
  const [isEditorFocused, setIsEditorFocused] = useState<boolean>(false);
  const [displayVariables, setDisplayVariables] = useState<boolean>(false);
  const theme = useTheme();
  const isComponentMounted = useRef(true);

  useEffect(() => {
    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  // Memoize safe parameters to prevent unnecessary recalculations
  const safeParameters = useMemo(() => parameters || {}, [parameters]);

  // Memoize the resolved text to avoid recalculations on every render
  const resolvedText = useMemo(() => {
    let result = htmlText;
    for (const key in safeParameters) {
      if (Object.prototype.hasOwnProperty.call(safeParameters, key)) {
        const escapedKey = key.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const pattern = new RegExp(`\\[\\[${escapedKey}\\]\\]`, 'g');
        result = result.replace(pattern, safeParameters[key]);
      }
    }
    return result;
  }, [htmlText, safeParameters]);

  // Debounce onChange to reduce frequent updates while typing
  const debouncedOnChange = useMemo(
    () =>
      debounce((newValue: string) => {
        onChange(newValue);
      }, 300),
    [onChange]
  );

  const onHandleChange = useCallback(
    (newValue: string) => {
      if (!isMount) setIsMount(true);
      debouncedOnChange(newValue);
    },
    [isMount, debouncedOnChange]
  );

  const handleCopy = useCallback(
    (key: string) => () => {
      try {
        navigator.clipboard.writeText(key);
      } catch (error) {
        console.error('Clipboard error:', error);
      }
    },
    []
  );

  const variables = entityType === EEditorType.Invoices ? PDF_INVOICE_TEXT_VARIABLES : PDF_OFFER_TEXT_VARIABLES;

  return (
    <Box
      onClick={() => setIsEditorFocused(true)}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setIsEditorFocused(false);
        }
      }}
      sx={{ fontFamily: defaultFont ? `${defaultFont} !important` : 'inherit' }}
    >
      {!isEditorFocused ? (
        <Box
          sx={{
            cursor: 'pointer',
            marginLeft: '-15px',
            '&:hover': {
              backgroundColor: theme.palette.action.hover
            },
            borderRadius: '8px !important',
            padding: '1px 15px!important',

            fontSize: '14px !important',
            marginBottom: '10px'
          }}
        >
          <PreviewHtmlText text={resolvedText} type={type} defaultFont={defaultFont} />
        </Box>
      ) : (
        <Stack position="relative">
          <DraftTextEditor value={htmlText} isMount={isMount} onChange={onHandleChange} page={editorType} isAutofocus={true} />
          <Box>
            <Button
              sx={{ marginTop: '5px' }}
              size="extraSmall"
              onClick={() => setDisplayVariables(!displayVariables)}
              variant="text"
              color="primary"
            >
              {!displayVariables ? (
                <FormattedMessage id={'settings.template-show-variables'} />
              ) : (
                <FormattedMessage id={'settings.template-hide-variables'} />
              )}
            </Button>
          </Box>
          <Box
            sx={{
              display: displayVariables ? 'block' : 'none'
            }}
          >
            {variables.map((x) => (
              <Button
                onClick={handleCopy(x.variable)}
                startIcon={<Copy20Regular />}
                key={x.key}
                variant="outlined"
                color="secondary"
                size="small"
                sx={{ margin: '2px' }}
              >
                <FormattedMessage id={x.intlId} />
              </Button>
            ))}
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default memo(EditorAndPreview);
