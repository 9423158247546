import { Box, styled } from '@mui/material';

interface IActionButton {
  isActive?: boolean;
  isFixed?: boolean;
}

export const ReplacementTextButton = styled(Box)<IActionButton>(({ theme, isActive }) => ({
  padding: '0 4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  height: '25px',
  backgroundColor: isActive ? theme.palette.primary.lighter : theme.palette.mode === 'dark' ? `#ffffff20` : theme.palette.secondary.lighter,
  borderRadius: isActive ? '6px' : '6px 0 0 6px',
  boxShadow: isActive ? `0 0 0  1.5px  ${theme.palette.primary.light}` : 'none',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: isActive ? theme.palette.primary.light : theme.palette.primary.light,
    boxShadow: `0 0 0  1.5px  ${theme.palette.primary.light}`,
    '& svg': {
      color: theme.palette.primary.contrastText
    }
  },
  '& svg': {
    color: isActive ? theme.palette.primary.main : theme.palette.secondary.main
  }
}));

export const HideLabelButton = styled(Box)<IActionButton>(({ theme, isActive }) => ({
  padding: '0 4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  height: '25px',
  backgroundColor: isActive ? theme.palette.primary.lighter : theme.palette.mode === 'dark' ? `#ffffff20` : theme.palette.secondary.lighter,
  borderRadius: isActive ? '6px' : '0 6px 6px 0',
  boxShadow: isActive ? `0 0 0  1.5px  ${theme.palette.primary.light}` : 'none',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: isActive ? theme.palette.primary.light : theme.palette.primary.light,
    boxShadow: `0 0 0  1.5px  ${theme.palette.primary.light}`,
    '& svg': {
      color: theme.palette.primary.contrastText
    }
  },
  '& svg': {
    color: isActive ? theme.palette.primary.main : theme.palette.secondary.main
  }
}));

export const SingleActionButton = styled(Box)<IActionButton>(({ theme, isActive, isFixed }) => ({
  padding: '0 4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  height: '25px',
  borderRadius: '6px',
  backgroundColor: isActive ? theme.palette.primary.lighter : theme.palette.mode === 'dark' ? `#ffffff20` : theme.palette.secondary.lighter,
  boxShadow: isActive ? `0 0 0  1.5px  ${theme.palette.primary.light}` : `0 0 0  1px  ${theme.palette.secondary.light}`,
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: isActive ? theme.palette.primary.light : theme.palette.primary.light,
    boxShadow: `0 0 0  1.5px  ${theme.palette.primary.light}`,
    '& svg': {
      color: theme.palette.primary.contrastText
    }
  },
  '& svg': {
    color: isActive ? theme.palette.primary.main : theme.palette.secondary.main
  },
  position: isFixed ? 'absolute' : 'relative',
  top: 8,
  right: 8,
  zIndex: 2
}));
