import PreviewAnswerWrapper from 'modules/organisation-plan-preview/components/Shared/PreviewAnswerWrapper';
import PreviewWidgetWrapper from 'modules/organisation-plan-preview/components/Shared/PreviewWidgetWrapper';
import { getNumericValuesWithUnits } from 'modules/organisation-plan-preview/utils/getNumericValueWithUnit';
import { renderInputLabel } from 'modules/organisation-plan-preview/utils/renderInputLabelHandler';
import { DisplayMode } from 'modules/organisation-plan-templates/types';
import {
  MultipleChoiceOrganisationPlanWidgetType,
  SingleChoiceOrganisationPlanWidgetType
} from 'modules/organisation-plan-templates/types/organisationPlanChoiceWidgetType';
import { useMemo } from 'react';
import { Layout } from 'react-grid-layout';

interface IPreviewChoiceWidgetProps {
  element: SingleChoiceOrganisationPlanWidgetType | MultipleChoiceOrganisationPlanWidgetType;
  layoutItem: Layout;
}

const PreviewChoiceWidget = ({ element, layoutItem }: IPreviewChoiceWidgetProps) => {
  const {
    label,
    widgetId,
    replacementLabel,
    replacementOptionsLabels,
    isMainLabelHidden,
    articles,
    optionLabels,
    sectionId,
    isBottomDivider,
    isHighlighted,
    listingOption,
    displayMode,
    isUnitLabelHidden
  } = element;
  const isColumnMode = (displayMode ?? DisplayMode.column) === DisplayMode.column;

  const selectedOptions = useMemo(() => {
    if ('selectedOptions' in element && Array.isArray(element.selectedOptions)) {
      return element.selectedOptions;
    } else if ('selectedOption' in element) {
      return [element.selectedOption];
    }
    return [];
  }, [element]);

  const renderOptionsHandler = useMemo(() => {
    const checkedOptions = new Set(selectedOptions);

    if (replacementOptionsLabels && replacementOptionsLabels.length) {
      const renderLabelOptions = replacementOptionsLabels.filter((labelObj: { id: string }) => checkedOptions.has(labelObj.id));
      const allLabelsHidden = renderLabelOptions.every((option) => option.isLabelHidden);

      if (allLabelsHidden) {
        return null;
      }

      return renderLabelOptions.map((labelObj, index) => {
        const id = labelObj.id;
        const isSelected = checkedOptions.has(labelObj.id);
        const articleOption = element?.articles?.find((article) => article.widgetOptionId === labelObj?.id);
        const priceOption = articleOption?.article?.price;
        const selectedOption = optionLabels.find((option) => option.id === labelObj?.id);
        const numericValue = getNumericValuesWithUnits({ selectedOption, priceOption, isUnitLabelHidden });
        return (
          <PreviewAnswerWrapper
            index={index}
            key={id}
            listingOption={listingOption}
            displayMode={displayMode}
            questionLabel={renderInputLabel(replacementLabel, label)}
            isQuestionLabelHidden={!!isMainLabelHidden}
            answerLabel={renderInputLabel(labelObj.replacementLabel, labelObj.label)}
            isLabelHidden={labelObj.isLabelHidden}
            isSelected={isSelected}
            numericValueLabel={numericValue}
          />
        );
      });
    }
    return null;
  }, [articles, optionLabels, replacementOptionsLabels, selectedOptions]);

  return (
    <PreviewWidgetWrapper
      isBottomDivider={isBottomDivider}
      isHighlighted={isHighlighted}
      widgetId={widgetId}
      sectionId={sectionId}
      layoutItem={layoutItem}
      questionLabel={renderInputLabel(replacementLabel, label)}
      isQuestionLabelHidden={!!isMainLabelHidden || !isColumnMode}
      isGrid={false}
    >
      {renderOptionsHandler}
    </PreviewWidgetWrapper>
  );
};

export default PreviewChoiceWidget;
