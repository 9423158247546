import { IEditorArticle, IEditorForForm } from 'modules/editor/types/editor';
import { QuestionnaireTaxType } from 'modules/questionnaires/types';
import { IUser } from 'shared/types/user';
import { IContactPeople, IEvent, IEventInfo } from './event';
import { IInvoiceTemplate, InvoiceTemplateListItemType } from './settingsInvoce';
import { IPagination } from './table';
import { IVenue, IVenueRoom } from './venue';

export enum EInvoicesQueryKeys {
  GetInvoices = 'invoices',
  GetSingleInvoice = 'invoice',
  GetInvoiceItems = 'invoice-items',
  GetInvoiceEditor = 'invoice-editor',
  GetInvoicesOfEvent = 'invoices-of-event',
  GetPrepaymentsInvoiceItemsOfEvent = 'prepayments-items-of-event'
}

export const API_INVOICES_ENDPOINTS = {
  GET_BY_ID: (id: string | number) => `invoices/${id}`,
  SEND_EMAIL: (id: string | number) => `invoices/${id}/send-invoice`
};

export enum EInvoiceType {
  invoice = 'Invoice',
  prepayment = 'PrePayment'
}

export enum InvoiceStatus {
  NewCreated = 'NewCreated',
  ReadyToSent = 'ReadyToSent',
  Clarification = 'Clarification',
  SentPerEmail = 'SentPerEmail',
  SentPerMail = 'SentPerMail',
  OnHold = 'OnHold',
  Canceled = 'Canceled'
}

export enum EInvoiceEditorArticleType {
  Custom = 'Custom',
  Imported = 'Imported',
  ImportedPrepayment = 'ImportedPrepayment'
}

interface IInvoiceRecipient {
  id: number;
  fullName: string;
  partnerName?: string;
  companyName?: string;
}

interface ITemplateContactDetails {
  id?: number;
  text: string;
}

export interface IInvoice {
  //TODO: update this, because main entity of invoice MUST have id
  id?: number;
  number?: string;
  recipient?: IUser;
  recipientId?: number;
  name?: string;
  type: EInvoiceType;
  valueType: QuestionnaireTaxType;
  state: InvoiceStatus;
  invoiceDate: string | Date;
  performanceDate?: string | Date;
  venue?: IVenueRoom;
  venueId?: number;
  invoiceTemplateId?: number;
  event: IEventInfo;
  eventId: number;
  finalAmount: number;
  tenantId: number;
  isSentEmail?: boolean;
  discount?: number;
}

export interface IInvoiceEditorDetails extends Omit<IInvoice, 'event' | 'recipient' | 'venue'> {
  appearance: Omit<IInvoiceTemplate, 'senderAddress'> & { footers: IInvoiceTemplate['footerTexts'] };
  event: IEvent;
  venue: IVenue;
  address: IInvoiceTemplate['senderAddress'];
  recipient: IUser;
}

export interface IInvoicePatchPayload
  extends Pick<IInvoice, 'type' | 'valueType' | 'state'>,
    Partial<Pick<IInvoiceTemplate, 'documentSubject' | 'welcomeText' | 'closingText'>> {
  id: number;
  discount?: number;
  footers?: IInvoiceTemplate['footerTexts'];
}

export interface IInvoiceItemsPayload {
  invoiceId: number;
  items: IEditorArticle[];
}

export interface IInvoiceFormData extends Omit<IInvoice, 'recipient'> {
  contactDetails?: ITemplateContactDetails[];
  senderAddress?: IInvoiceTemplate['senderAddress'];
  legalTax?: number;
  individualTax?: number;
  discount?: number;
  invoiceTemplate?: Omit<IInvoiceTemplate, 'senderAddress'> & { footers: IInvoiceTemplate['footerTexts'] };
  articles: IEditorArticle[];
  recipient: IUser;
}

export interface IInvoiceListItem {
  id: number;
  number: string;
  recipient: IInvoiceRecipient;
  name: string;
  type: EInvoiceType;
  valueType: QuestionnaireTaxType;
  state: InvoiceStatus;
  createDate: string;
  performanceDate?: string;
  venue: IVenueRoom;
  invoiceTemplateId: number;
  event?: IEventInfo;
  finalAmount: number;
  isSentEmail?: boolean;
}

export interface IInvoiceDialogForm {
  id?: number;
  number: string;
  type: EInvoiceType;
  state: InvoiceStatus;
  valueType: QuestionnaireTaxType;
  invoiceDate: string | null | Date;
  performanceDate: string | null | Date;
  event?: Partial<IEvent<string>>;
  invoiceTemplate: Partial<InvoiceTemplateListItemType> | null;
  recipient: Partial<IContactPeople> | null;
  venue: Partial<IEvent<string>['venue']> | null;
  isSentEmail?: boolean;
}

export interface IInvoiceForSave {
  id: number;
  number: string;
  type: EInvoiceType;
  state: InvoiceStatus;
  valueType: QuestionnaireTaxType;
  invoiceDate: string | Date | null;
  performanceDate: string | Date | null;
  eventId?: number;
  invoiceTemplateId?: number;
  recipientId?: number;
  venueId?: number;
  isSentEmail?: boolean;
}

export interface IInvoicePostPayload extends Pick<IInvoiceListItem, 'valueType' | 'type'> {
  invoiceDate: string | Date;
  performanceDate?: string | Date;
  eventId?: number;
  invoiceTemplateId: number;
  recipientId: number;
  venueId: number;
}

export type IInvoiceRemove = {
  isOpen: boolean;
  invoice: IInvoiceRecipient | null;
};

export interface IInvoiceByEventId {
  id: number;
  number: string;
  type: EInvoiceType;
  createDate?: string;
  finalAmount: number;
}

export interface ICalculateParams {
  title: string;
  inputValue?: number;
  value?: number;
  name: string;
  isInput?: boolean;
  blockedSymbols?: string;
}

export interface IEmailInvoice {
  invoiceId: string | number;
  subject: string;
  body: string;
  articles: IEditorArticle<EInvoiceEditorArticleType>[];
  pagination: IPagination;
}

export type IInvoiceEditor = IEditorForForm<
  InvoiceStatus,
  IEventInfo,
  EInvoiceType,
  IInvoiceEditorDetails['appearance'],
  EInvoiceEditorArticleType
>;

export interface IInvoiceSendEmail extends IInvoiceEditor {
  customUrl?: string;
  performanceDate?: string;
  invoiceDate?: string;
}

export interface IPrepareDataToSendEmailInvoice {
  invoice: IInvoiceSendEmail;
  subject: string;
  template: string;
}
