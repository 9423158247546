import { AddCircle16Regular, Delete16Regular } from '@fluentui/react-icons';
import { Box, Button, Divider, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import { ValuesEditorType } from 'modules/editor/types/editor';
import { calculateNetTotalForGross, calculateTotal, listTaxValues } from 'modules/editor/utils/editResultCalculation';
import FormatPrice from 'modules/pricing-sidebar/components/FormatPrice';
import { QuestionnaireTaxType } from 'modules/questionnaires/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import NumericInput from 'shared/components/NumberInputField';
import { useCurrentCurrencyFormat } from 'shared/hooks/regionalSettings';
import * as Styles from './EditorResultCalculation.styles';

const EditorResultCalculation = ({ fontFamily }: { fontFamily: string }) => {
  const intl = useIntl();
  const theme = useTheme();
  const [isDiscount, setIsDiscount] = useState(false);
  const borderColor = theme.palette.mode === 'dark' ? '#ffffff20' : '#00000020';
  const currencySign = useCurrentCurrencyFormat();

  const { values, setFieldValue } = useFormikContext<ValuesEditorType>();
  const isNet = values.taxType === QuestionnaireTaxType.Net;

  useEffect(() => {
    if (values?.discount !== 0 && values?.discount !== undefined && values?.discount !== null) {
      setIsDiscount(true);
    }
  }, [values.articles, setFieldValue]);

  const totalAmount = useMemo(() => {
    return calculateTotal(values.articles, values?.taxType);
  }, [values.articles, values.taxType]);

  const taxValues = useMemo(() => {
    return listTaxValues(values.articles, values?.taxType, values?.discount, intl);
  }, [values.articles, values?.taxType, values?.discount, intl]);

  const finalNetAmount = useMemo(() => {
    const netAmount = calculateNetTotalForGross(totalAmount, taxValues, values.discount ?? 0);
    return netAmount;
  }, [totalAmount, taxValues, values.discount]);

  const getTaxesSum = useCallback(
    () =>
      Object.entries(taxValues).reduce((acc, [_, data]) => {
        return acc + (data?.value ?? 0);
      }, 0),
    [taxValues]
  );
  const finalGrossAmount = useMemo(() => {
    const taxesSum = getTaxesSum();
    const finaleNet = (totalAmount ?? 0) - totalAmount * ((values?.discount ?? 0) / 100);
    const finaleIfNet = finaleNet + taxesSum;
    const finaleGross = (totalAmount ?? 0) - totalAmount * ((values?.discount ?? 0) / 100);
    return isNet ? finaleIfNet : finaleGross;
  }, [finalNetAmount, taxValues]);

  const handleRemoveDiscount = () => {
    setIsDiscount(false);
    setFieldValue('discount', 0);
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-end">
      <Styles.Container>
        {!isDiscount && (
          <Button startIcon={<AddCircle16Regular />} sx={{ alignContent: 'left' }} size="small" onClick={() => setIsDiscount(true)}>
            {intl.formatMessage({ id: 'invoices.invoice-editor-add-discount' })}
          </Button>
        )}
        {isDiscount && (
          <>
            <Divider sx={{ borderColor: borderColor }} />
            <Stack p="5px 0" direction="row" alignItems="center" justifyContent="space-between">
              <Typography fontStyle={'italic'} fontFamily={fontFamily}>
                {intl.formatMessage({ id: 'invoices.invoice-editor-result-subtotal' })}
              </Typography>
              <Stack direction="row" justifyContent="space-between" alignItems="center" width="150px">
                <Typography fontStyle={'italic'} fontFamily={fontFamily}>
                  {currencySign}
                </Typography>
                <Typography fontStyle={'italic'} fontFamily={fontFamily}>
                  {<FormatPrice value={totalAmount ?? 0} isHideSymbol />}
                </Typography>
              </Stack>
            </Stack>
            <Divider sx={{ borderStyle: 'dashed', borderColor: borderColor }} />
            <Stack direction="row" alignItems="center" justifyContent="space-between" position="relative">
              <Stack direction="row" justifyContent="right" alignItems="center" gap="1px">
                <IconButton sx={{ position: 'absolute', right: '-35px' }} size="small" color="error" onClick={handleRemoveDiscount}>
                  <Delete16Regular />
                </IconButton>
                <Typography fontStyle={'italic'} fontFamily={fontFamily}>
                  {intl.formatMessage({ id: 'invoices.minus' })}
                </Typography>
                <NumericInput
                  width="40px"
                  size="small"
                  isDecimal
                  maxDecimalDigits={2}
                  textAlign="right"
                  name={'discount'}
                  value={values?.discount ?? 0}
                  onHandleInputChange={(value) => setFieldValue('discount', value)}
                />
                <Typography fontStyle={'italic'} fontFamily={fontFamily}>
                  {intl.formatMessage({ id: 'invoices.invoice-editor-result-discount' })}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" width="150px">
                <Typography fontStyle={'italic'} fontFamily={fontFamily}>
                  {currencySign}
                </Typography>
                <Typography fontStyle={'italic'} fontFamily={fontFamily}>
                  {<FormatPrice value={-(totalAmount * ((values?.discount ?? 0) / 100))} isHideSymbol />}
                </Typography>
              </Stack>
            </Stack>
          </>
        )}
        <Divider sx={{ borderColor: borderColor }} />
        <Stack p="5px 0" direction="row" alignItems="center" justifyContent="space-between">
          <Typography fontStyle={'italic'} fontFamily={fontFamily}>
            {isNet
              ? intl.formatMessage({ id: 'invoices.invoice-editor-result-total-net' })
              : intl.formatMessage({ id: 'invoices.invoice-editor-result-total-gross' })}
          </Typography>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="150px">
            <Typography fontStyle={'italic'} fontFamily={fontFamily}>
              {currencySign}
            </Typography>
            <Typography fontStyle={'italic'} fontFamily={fontFamily}>
              {<FormatPrice value={(totalAmount ?? 0) - totalAmount * ((values?.discount ?? 0) / 100)} isHideSymbol />}
            </Typography>
          </Stack>
        </Stack>
        <Divider sx={{ borderStyle: 'dashed', borderColor: borderColor }} />
        {taxValues.map((item, index) => (
          <Stack key={index} p="5px 0" direction="row" alignItems="center" justifyContent="space-between">
            <Typography fontStyle={'italic'} fontFamily={fontFamily}>
              {item.title}
            </Typography>
            <Stack direction="row" justifyContent="space-between" alignItems="center" width="150px">
              <Typography fontStyle={'italic'} fontFamily={fontFamily}>
                {currencySign}
              </Typography>
              <Typography fontStyle={'italic'} fontFamily={fontFamily}>
                {<FormatPrice value={item.value ?? 0} isHideSymbol />}
              </Typography>
            </Stack>
          </Stack>
        ))}

        {!isNet && (
          <>
            <Divider sx={{ borderStyle: 'dashed', borderColor: borderColor }} />
            <Stack p="5px 0" direction="row" alignItems="center" justifyContent="space-between">
              <Typography fontStyle={'italic'} fontFamily={fontFamily}>
                {intl.formatMessage({ id: 'invoices.invoice-editor-result-total-net' })}
              </Typography>
              <Stack direction="row" justifyContent="space-between" alignItems="center" width="150px">
                <Typography fontStyle={'italic'} fontFamily={fontFamily}>
                  {currencySign}
                </Typography>
                <Typography fontStyle={'italic'} fontFamily={fontFamily}>
                  {<FormatPrice value={finalNetAmount} isHideSymbol />}
                </Typography>
              </Stack>
            </Stack>
          </>
        )}
        <Divider sx={{ mb: '1px', borderColor: borderColor }} />
        <Divider sx={{ borderColor: borderColor }} />
        <Stack p="5px 0" direction="row" alignItems="center" justifyContent="space-between">
          <Typography fontSize={'1rem'} fontFamily={fontFamily} fontWeight={600}>
            {intl.formatMessage({ id: 'invoices.invoice-editor-final-amount' })}
          </Typography>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="150px">
            <Typography fontSize={'1rem'} fontFamily={fontFamily} fontWeight={600}>
              {currencySign}
            </Typography>
            <Typography fontSize={'1rem'} fontFamily={fontFamily} fontWeight={600}>
              {<FormatPrice value={finalGrossAmount ?? 0} isHideSymbol />}
            </Typography>
          </Stack>
        </Stack>
      </Styles.Container>
    </Box>
  );
};

export default EditorResultCalculation;
