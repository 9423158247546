import { Avatar, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ArticleContainer = styled('article')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '84px',
  '&: last-child': {
    marginBottom: '13px'
  }
}));

export const ArticleColumn = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

export const ArticleContent = styled(Box)(() => ({
  marginBottom: '30px',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, auto)',
  gap: '30px 50px'
}));

export const ArticleTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 500,
  marginBottom: 4
}));

export const LogoImg = styled(Avatar)(() => ({
  maxHeight: 100,
  minHeight: 100,
  width: 'fit-content',
  height: '100%',

  '.MuiAvatar-img': {
    maxWidth: 294,
    maxHeight: 100,
    minHeight: 100,
    objectFit: 'contain'
  }
}));
