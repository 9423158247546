import { Box, Stack, Typography } from '@mui/material';
import { useGetCurrentTenant } from 'api/hooks/tenant/useGetCurrentTenant';
import { useFormikContext } from 'formik';
import { EditorType, ValuesEditorType } from 'modules/editor/types/editor';
import { FormattedMessage } from 'react-intl';
import countries from 'shared/data/countries';
import { IVenue } from 'shared/types/venue';
import { getStorageFileUrl, typeImages } from 'shared/utils/getStorageFileUrl';
import * as Styles from './EditorDetails.styles';

const logoSrc = '/assets/images/builder/sidebar-logo.svg';

interface IData {
  intlId: string;
  value: string | null;
}
export interface IEditorDetails {
  templateLogo?: string;
  fontFamily?: string;
  type: EditorType;
  data: IData[];
  venue?: IVenue;
}

const EditorDetails = ({ templateLogo, fontFamily, data, venue }: IEditorDetails) => {
  const { values } = useFormikContext<ValuesEditorType>();
  const { data: tenantData } = useGetCurrentTenant();
  const tenantLogo = tenantData?.loginViewSetting ? tenantData.loginViewSetting.loginLogoUrl : '';
  const logoUrl = templateLogo || tenantLogo;
  const templateAddress = `${values.senderAddress?.corporateName} • ${values.senderAddress?.street} • ${values.senderAddress?.zipCode} ${values.senderAddress?.city}`;
  const venueAddress = `${venue?.name} • ${venue?.address?.street} • ${venue?.address?.postalCode} ${venue?.address?.city}`;
  const displayedAddress = !!values.senderAddress?.corporateName ? templateAddress : venueAddress;
  return (
    <>
      <Box display="flex" justifyContent="right" m="0 40px 30px 40px">
        <Styles.LogoImg variant="rounded" src={(logoUrl && getStorageFileUrl(logoUrl, typeImages.Internal)) || logoSrc} alt="logo" />
      </Box>
      <Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" width="100%" mb={4}>
          <Stack ml="70px">
            <Typography
              maxWidth="350px"
              fontFamily={fontFamily}
              variant="caption"
              color="textSecondary"
              fontStyle={'italic'}
              paddingBottom="5px !important"
            >
              {displayedAddress}
            </Typography>
            <Typography fontFamily={fontFamily}>{values?.recipient?.details?.companyName}</Typography>
            <Typography fontFamily={fontFamily}>{`${values?.recipient?.name ?? ''} ${values?.recipient?.surname ?? ''}`}</Typography>
            {values?.recipient?.details?.partner?.name && (
              <Typography
                fontFamily={fontFamily}
              >{`${values?.recipient?.details?.partner?.name} ${values?.recipient?.details?.partner?.surname}`}</Typography>
            )}
            {values.recipient?.details?.address?.street && (
              <Typography fontFamily={fontFamily}>{values.recipient?.details?.address?.street}</Typography>
            )}
            <Typography fontFamily={fontFamily}>
              {values.recipient?.details?.address?.postalCode ?? ''} {values.recipient?.details?.address?.city}
            </Typography>
            {!!values.recipient?.details?.address?.country && (
              <Typography fontFamily={fontFamily}>
                {countries.find((item) => item.code === values.recipient?.details?.address?.country)?.label ?? ''}
              </Typography>
            )}
          </Stack>
          <Box mr="40px" mt="20px">
            <table>
              <tbody>
                {data.map(({ intlId, value }, index) => (
                  <tr key={index}>
                    <td>
                      <Typography fontFamily={fontFamily} pr="60px">
                        <FormattedMessage id={intlId} />
                      </Typography>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <Typography fontFamily={fontFamily}>{value}</Typography>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default EditorDetails;
