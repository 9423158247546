import { TableCell as MuiTableCell, TableRow as MuiTableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TableCellNestedRow = styled(MuiTableCell)(() => ({
  '.MuiTableRow-root &.MuiTableCell-root:first-of-type': {
    padding: 12
  },
  '.MuiTableRow-root &.MuiTableCell-root:last-of-type': {
    padding: 12
  }
}));

export const TableHeaderRow = styled(MuiTableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '& .MuiTableCell-root': {
    textTransform: 'capitalize'
  },

  '& .Mui-active': {
    color: `${theme.palette.primary.main} !important`,
    '& .MuiTableSortLabel-icon': {
      color: `${theme.palette.primary.main} !important`
    }
  },

  '& .MuiTableCell-head': {
    width: '100px'
  },
  '& .MuiTableCell-head:first-of-type': {
    paddingLeft: '12px !important',
    width: '50px !important'
  },
  '& .MuiTableCell-head:last-of-type': {
    paddingRight: '8px !important',
    width: '50px !important'
  },
  '& .MuiTableSortLabel-root': {
    minWidth: '0px'
  }
}));
