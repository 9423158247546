import OrganisationPlanTemplateSection from 'modules/organisation-plan-templates/components/OrganisationPlanTemplateSection';
import { IOrganisationPlanSection } from 'modules/organisation-plan-templates/types/organisationPlanSection';
import * as React from 'react';
import { useSelector } from 'store';
import { selectOrganizationPlanSectionById } from 'store/organisation-plan-template/organisation-plan-template.selectors';

interface IOrgPlanSectionWrapperProps {
  sectionId: IOrganisationPlanSection['sectionId'];
  sectionIndex: number;
  isLastSection: boolean;
}

const OrgPlanSectionWrapper = ({ sectionId, sectionIndex, isLastSection }: IOrgPlanSectionWrapperProps): React.ReactElement | null => {
  const section = useSelector((state) => selectOrganizationPlanSectionById(state, sectionId));

  if (!section) return null;
  return (
    <OrganisationPlanTemplateSection isLastSection={isLastSection} key={section.sectionId} section={section} sectionIndex={sectionIndex} />
  );
};

export default OrgPlanSectionWrapper;
