import { Box, Divider, Grid } from '@mui/material';
import OrganisationPlanWidgetsPaletteItem from 'modules/organisation-plan-templates/components/OrganisationPlanWidgetsPaletteItem';
import { ISideBarWidget, ORGANISATION_PLAN_WIDGET_TYPES } from 'modules/organisation-plan-templates/types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'store';
import { setDraggableItemOrganisationPlan } from 'store/organisation-plan-template/organisation-plan-template.slice';
import * as Styles from './WidgetBar.styles';
import { ORGANISATION_PLAN_BUILDER_SIDEBAR_WIDGETS_LIST } from './widgetList';

const WidgetsBar = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const onDragStart = (item: ISideBarWidget) => {
    dispatch(setDraggableItemOrganisationPlan(item));
  };
  const onDragEnd = () => {
    dispatch(setDraggableItemOrganisationPlan(null));
  };
  return (
    <>
      <Grid item>
        <Styles.WidgetBarTypography>
          <FormattedMessage id="builder.select-elements" />
        </Styles.WidgetBarTypography>
      </Grid>
      <Divider sx={{ borderWidth: '3px' }} />
      <Box padding="10px" display="grid" gridTemplateColumns="repeat(2, 1fr)" gap="10px" maxWidth="100%">
        {ORGANISATION_PLAN_BUILDER_SIDEBAR_WIDGETS_LIST.map((item, index) => {
          return (
            <Box
              key={`${item.label}-${index}`}
              className="droppable-element"
              draggable={item.widget.type === ORGANISATION_PLAN_WIDGET_TYPES.infoFromQuestionnaire ? false : true}
              unselectable="on"
              onDragStart={() => onDragStart(item)}
              onDragEnd={onDragEnd}
            >
              <OrganisationPlanWidgetsPaletteItem
                imgUrl={item.imgUrl}
                label={intl.formatMessage({ id: item.formatId })}
                customImageSize={item.customImageSize}
              />
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default WidgetsBar;
