import TextField, { TextFieldProps } from '@mui/material/TextField';
import { debounce } from 'lodash';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';

export type DebouncedTextFieldProps = TextFieldProps & {
  /** Delay in milliseconds to debounce the onChange handler. Default is 300ms */
  debounceTime?: number;
};

const DebouncedTextField = forwardRef<HTMLInputElement, DebouncedTextFieldProps>((props, ref) => {
  const { debounceTime = 300, onChange, value: propValue, ...rest } = props;

  // Initialize internal state for the value
  const [value, setValue] = useState(propValue || '');

  // Create a debounced version of the onChange callback
  const debouncedOnChange = useMemo(() => {
    if (!onChange) return undefined;
    // Wrap the callback in lodash.debounce
    return debounce((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange(event);
    }, debounceTime);
  }, [onChange, debounceTime]);

  // Cleanup the debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedOnChange?.cancel();
    };
  }, [debouncedOnChange]);

  // Update internal state and forward change via debounced callback
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // Persist the event so it can be used asynchronously
    event.persist();
    setValue(event.target.value);
    debouncedOnChange && debouncedOnChange(event);
  };

  return (
    <TextField
      {...rest}
      // Forward the ref to the underlying input element
      inputRef={ref}
      value={value}
      onChange={handleChange}
    />
  );
});

DebouncedTextField.displayName = 'DebouncedTextField';

export default DebouncedTextField;
