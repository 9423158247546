import { SelectProps } from '@mui/material';
import StatusSelect from 'shared/components/StatusSelect';
import { useGetDataByRole } from 'shared/hooks/useGetDataByRole';
import { InvoiceStatus } from 'shared/types/invoice';

interface IInvoiceStatusSelect {
  name: string;
  value: InvoiceStatus;
  handleChange: SelectProps['onChange'];
  isSentEmail: boolean;
}

const InvoiceStatusSelect = ({
  name,
  handleChange,
  isSentEmail,
  value,
  ref: _ref,
  type: _type,
  ...rest
}: IInvoiceStatusSelect & SelectProps) => {
  const { invoiceStateChips } = useGetDataByRole();

  const filteredOptions = Object.fromEntries(
    Object.entries(invoiceStateChips).filter(([key]) => {
      if (key === InvoiceStatus.SentPerEmail) {
        return isSentEmail === true;
      }
      return true;
    })
  );
  return (
    <StatusSelect
      {...rest}
      name={name}
      value={value}
      handleChange={handleChange}
      options={filteredOptions}
      sx={{ width: { xs: '100%', sm: 'auto' } }}
    />
  );
};

export default InvoiceStatusSelect;
