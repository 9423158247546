import { bruttoArticle, nettoArticle } from 'modules/articles/utils/pricingCalc';
import { EEditorArticleItemType, IEditorArticleItem } from 'modules/editor/types/editor';
import { ArticleItemsPriceFieldNameType, ArticleTotalPriceFieldNameType } from 'modules/invoices/types';
import { TO_FIXED_FOR_NOT_PRICE } from 'modules/pricing-sidebar/constants/inputs';
import { QuestionnaireTaxType } from 'modules/questionnaires/types';

export const getNameOfPriceField = (taxType: QuestionnaireTaxType): ArticleItemsPriceFieldNameType => {
  return taxType === QuestionnaireTaxType.Net ? 'priceNetto' : 'priceBrutto';
};

export const getNameOfTotalPriceField = (taxType: QuestionnaireTaxType): ArticleTotalPriceFieldNameType => {
  return taxType === QuestionnaireTaxType.Net ? 'totalNet' : 'totalGross';
};

/**
 * util function round number to `TO_FIXED_FOR_NOT_PRICE` decimal scale
 * @param value number
 * @returns number
 */
export const roundNumbers = (value: number | string): number => {
  const newValue = typeof value === 'number' ? value : 0;
  return Number(newValue.toFixed(TO_FIXED_FOR_NOT_PRICE));
};

export const calculateAmount = (values: IEditorArticleItem[], invoiceValueType: QuestionnaireTaxType): number => {
  const priceFieldName: ArticleItemsPriceFieldNameType = getNameOfPriceField(invoiceValueType);
  let basePrice = 0;
  const sum = values.reduce((acc, item) => {
    if (item.type === EEditorArticleItemType.Base) {
      const number = roundNumbers(item?.[priceFieldName] ?? 0);
      basePrice = number ?? 0;
      return acc;
    }
    if (item.type === EEditorArticleItemType.SecondUnit) {
      return roundNumbers(acc * item.count);
    } else {
      const number = roundNumbers(item?.[priceFieldName] ?? 1);
      const calc = number * item.count;
      return roundNumbers(acc + calc);
    }
  }, 0);
  return sum + basePrice;
};

export const calculateNettoIfChangedBrutto = (name: string, newValue: number | string, invoiceType: QuestionnaireTaxType, tax: number) => {
  const parsedValue = typeof newValue === 'string' ? parseFloat(newValue) : newValue;
  return invoiceType === QuestionnaireTaxType.Net && name === 'priceNetto' ? { priceBrutto: bruttoArticle(parsedValue, tax) } : {};
};

export const calculateBruttoIfChangedNetto = (name: string, newValue: number | string, invoiceType: QuestionnaireTaxType, tax: number) => {
  const parsedValue = typeof newValue === 'string' ? parseFloat(newValue) : newValue;
  return invoiceType === QuestionnaireTaxType.Gross && name === 'priceBrutto' ? { priceNetto: nettoArticle(parsedValue, tax) } : {};
};
