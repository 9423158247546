import { ArrowSync24Regular, Copy20Regular, Dismiss20Regular, Mail24Regular, Warning20Filled } from '@fluentui/react-icons';
import { Box, Button, Card, Grid, IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import { useGetCurrentUser } from 'api/hooks/user/useGetUser';
import { useState } from 'react';

import { FallbackProps } from 'react-error-boundary';
import { FormattedMessage } from 'react-intl';

const UnexpectedError = (props: FallbackProps) => {
  const { error, resetErrorBoundary } = props;
  const { data } = useGetCurrentUser();
  const [showError, setShowError] = useState(false);

  const mailContent = `Name: ${data?.name} ${data?.surname} \nRolle: ${data?.role} \nTenant: ${data?.tenant?.name} \n \n${error.stack}`; // You can change the email
  let mailToLink = 'mailto:support@the-eventcloud.com?subject=Fehlermeldung&body=' + encodeURIComponent(mailContent);

  return (
    <Stack p="10px" gap="20px" width="100dvw" height="100dvh" justifyContent="center" alignItems="center">
      <Stack gap="20px" justifyContent="start" alignItems="flex-start">
        <Stack width="100%">
          <Box>
            <Typography variant="h1" fontSize={'3rem'} fontWeight="1000">
              Upps! 😅
            </Typography>
          </Box>
        </Stack>
        <Stack gap="5px">
          <Typography fontSize={'1rem'} variant="body1" sx={{ maxWidth: '500px' }}>
            <FormattedMessage id="error.error-message-1" />
          </Typography>
          <Typography fontSize={'1rem'} variant="body1" sx={{ maxWidth: '500px' }}>
            <FormattedMessage id="error.error-message-2" />
          </Typography>
          <Typography fontSize={'1rem'} variant="body1" sx={{ maxWidth: '500px' }}>
            <FormattedMessage id="error.thank-you" />
          </Typography>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Button fullWidth startIcon={<ArrowSync24Regular />} variant="contained" size="large" onClick={resetErrorBoundary}>
              <FormattedMessage id="error.reload-page" />
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button fullWidth startIcon={<Mail24Regular />} variant="outlined" size="large" component={Link} href={mailToLink}>
              <FormattedMessage id="error.report-error" />
            </Button>
          </Grid>
        </Grid>
      </Stack>
      {showError && (
        <Card
          sx={{
            m: 2,
            boxShadow: ' rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
            maxWidth: '100dvw'
          }}
        >
          <Stack p="5px 5px 5px 10px" direction="row" justifyContent="space-between" alignItems={'center'}>
            <Stack direction="row" gap="10px" alignItems={'center'}>
              <Warning20Filled color="orange" />
              <Typography variant="h5">
                <FormattedMessage id="error.error-message" />
              </Typography>
            </Stack>
            <Stack direction="row" gap="5px" alignItems={'center'}>
              <Tooltip title={<FormattedMessage id="error.copy-error" />} arrow placement="top">
                <IconButton size="small" onClick={() => navigator.clipboard.writeText(error.stack)} title={error.stack}>
                  <Copy20Regular />
                </IconButton>
              </Tooltip>
              <Tooltip title={<FormattedMessage id="error.close-error-report" />} arrow placement="top">
                <IconButton size="small" onClick={() => setShowError(false)}>
                  <Dismiss20Regular />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
          <Box p="0px 20px" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', overflow: 'auto', height: '100%' }}>
            <pre>{error.stack}</pre>
          </Box>
        </Card>
      )}
      <Typography color="#00000030" sx={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => setShowError(!showError)}>
        {!showError && <FormattedMessage id="error.show-error" />}
      </Typography>
    </Stack>
  );
};

export default UnexpectedError;
