import { MenuItem, Select, SelectProps } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface IStatusOption {
  [key: string]: {
    intlId: string;
    color: string;
    bgColor: string;
  };
}
interface IStatusSelect {
  name: string;
  value: string;
  handleChange: SelectProps['onChange'];
  options: IStatusOption;
  disabledStatus?: string[];
  includeCurrentIfDisabled?: boolean;
}
const StatusSelect = ({
  name,
  handleChange,
  value,
  options,
  disabledStatus = [],
  ref: _ref,
  type: _type,
  ...rest
}: IStatusSelect & SelectProps) => {
  const intl = useIntl();

  const menuItems = useMemo(
    () =>
      Object.entries(options).map(([key, obj]) => (
        <MenuItem key={`select-state-${obj.intlId}`} value={key} divider disabled={disabledStatus.includes(key)}>
          <FormattedMessage id={obj.intlId} />
        </MenuItem>
      )),
    [options, disabledStatus]
  );

  return (
    <Select
      {...rest}
      value={value ?? null}
      name={name}
      onChange={handleChange}
      placeholder={intl.formatMessage({ id: 'events.select-here' })}
      inputProps={{ placeholder: intl.formatMessage({ id: 'events.select-here' }) }}
    >
      {menuItems}
    </Select>
  );
};

export default StatusSelect;
