import { Box, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Content = styled(Grid)(() => ({
  padding: '10px',
  height: '100%',
  '& .MuiSelect-select.MuiSelect-outlined': {
    padding: '9px 12px'
  }
}));

export const DisabledButtonIcon = styled(Box)(() => ({
  cursor: 'pointer',
  position: 'absolute',
  top: 0,
  left: '10px',
  marginLeft: '10px',
  width: '80%',
  height: '100%',
  padding: '0',
  margin: '0'
}));

export const StackSideRight = styled(Stack)(({ theme }) => ({
  color: theme.palette.secondary.dark
}));

export const BoldText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontWeight: 600,
  margin: '0px 0px 0px 10px',
  textWrap: 'nowrap'
}));
