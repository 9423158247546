import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useGetOfferArticleItemsOptional } from 'api/hooks/offers/useGetOfferArticleItemsOptional';
import { useFormikContext } from 'formik';
import { EEditorType, IEditorArticle } from 'modules/editor/types/editor';
import DialogImportWarning from 'modules/invoices/components/DialogImportWarning';
import { addBasePriceUnitWordingToArticle, addInnerIdToArticle, updateOrderArticle } from 'modules/invoices/utils/prepareData';
import { checkIsImportedOptionalArticle, generateListOfferArticlesFromDBOptionalArticles } from 'modules/offers/utils/prepareData';
import { PdfTemplateParameters } from 'modules/settings/types';
import { memo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import EditorAndPreview from 'shared/components/DraftTextEditor/EditorAndPreview';
import { EOfferEditorArticleOptionalType, IOfferEditor } from 'shared/types/offer';
import * as Styles from './OptionalTextImportButtons.styles';

interface IIsImportAgain {
  isOpen: boolean;
  items: IEditorArticle[];
  type: EOfferEditorArticleOptionalType | null;
}

interface IOptionalTextImportButtons {
  parameters: PdfTemplateParameters;
  fontFamily: string;
  type: EEditorType;
}
const initialIsImportAgain: IIsImportAgain = { isOpen: false, items: [], type: null };

const OptionalTextImportButtons = ({ parameters, fontFamily, type }: IOptionalTextImportButtons) => {
  const intl = useIntl();
  const [importedAgain, setImportedAgain] = useState<IIsImportAgain>(initialIsImportAgain);

  const { values, setFieldValue } = useFormikContext<IOfferEditor>();

  const { mutateAsync: getArticles, isLoading: isArticlesLoading } = useGetOfferArticleItemsOptional();

  const handleImportArticles = async () => {
    if (!values?.id) return;
    const type = EOfferEditorArticleOptionalType.OptionalImported;
    const res = await getArticles(values?.id);

    const articlesWithInnerId = addInnerIdToArticle(res);
    const resArticles = addBasePriceUnitWordingToArticle(articlesWithInnerId, intl);
    const isImportedArticle = checkIsImportedOptionalArticle(
      values?.articlesOptional as IEditorArticle<EOfferEditorArticleOptionalType>[],
      type
    );
    if (isImportedArticle) {
      return setImportedAgain({
        isOpen: true,
        items: resArticles,
        type: EOfferEditorArticleOptionalType.OptionalImported
      });
    }
    setFieldValue('articlesOptional', updateOrderArticle(values?.articlesOptional, resArticles));
  };

  const handleImportWithReplacement = () => {
    if (!importedAgain?.type) return;
    const newArticles = generateListOfferArticlesFromDBOptionalArticles(
      EOfferEditorArticleOptionalType.OptionalImported,
      (values?.articlesOptional as IEditorArticle<EOfferEditorArticleOptionalType>[]) ?? [],
      (importedAgain?.items as IEditorArticle<EOfferEditorArticleOptionalType>[]) ?? []
    );
    setFieldValue('articlesOptional', updateOrderArticle(newArticles));
    handleCloseWarningImport();
  };

  const handleImportAll = () => {
    setFieldValue('articlesOptional', updateOrderArticle(values?.articlesOptional, importedAgain?.items));
    handleCloseWarningImport();
  };

  const handleCloseWarningImport = () => {
    setImportedAgain(initialIsImportAgain);
  };

  return (
    <>
      <Box margin="0 40px">
        <Box>
          <Typography variant="h4" component="h2" sx={{ fontFamily: fontFamily }} fontStyle="italic">
            <FormattedMessage id="offer.editor-optional-articles" />
          </Typography>
        </Box>
        <Box sx={{ fontFamily: fontFamily }}>
          <EditorAndPreview
            entityType={type}
            type="optionalText"
            defaultFont={fontFamily}
            editorType="PdfTemplate"
            htmlText={values?.settingsTemplate?.optionalText ?? ''}
            parameters={parameters}
            onChange={(newValue) => setFieldValue('settingsTemplate', { ...values.settingsTemplate, optionalText: newValue })}
          />
        </Box>
      </Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Styles.ButtonStack>
          <Styles.BlockTooltip>
            <Button
              fullWidth
              size="medium"
              variant="outlined"
              color="secondary"
              onClick={handleImportArticles}
              disabled={isArticlesLoading || !values?.data}
              startIcon={isArticlesLoading && <CircularProgress size={'20px'} />}
            >
              <FormattedMessage id="offer.editor-import-optional-articles" />
            </Button>
          </Styles.BlockTooltip>
        </Styles.ButtonStack>
      </Stack>

      <DialogImportWarning
        isOpen={importedAgain?.isOpen}
        closeDialog={handleCloseWarningImport}
        handleImportWithReplacement={handleImportWithReplacement}
        handleImportAll={handleImportAll}
      />
    </>
  );
};

export default memo(OptionalTextImportButtons);
