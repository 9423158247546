import { CaretDown16Filled, CaretUp16Filled } from '@fluentui/react-icons';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Styles from 'modules/events/components/ReactTableEvents.styles';
import { useIntl } from 'react-intl';
import { ColumnInstance } from 'react-table';

type IHeaderSortProps<T extends object = {}> = {
  column: ColumnInstance<T> & { intlId: string };
  sort?: boolean;
  disableMaxWidth?: boolean;
};

export const HeaderSort = <T extends object = {}>({ column, sort, disableMaxWidth = false }: IHeaderSortProps<T>) => {
  const theme = useTheme();
  const intl = useIntl();
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        display: 'inline-flex',
        justifyContent: 'space-between',
        width: '100%'
      }}
    >
      {column.intlId ? (
        <Tooltip arrow title={intl.formatMessage({ id: column.intlId })} placement="top">
          <Styles.EventsTableColumnWrapper customMaxWidth={disableMaxWidth ? null : 120}>
            {column.render('Header')}
          </Styles.EventsTableColumnWrapper>
        </Tooltip>
      ) : (
        <Box>{column.render('Header')}</Box>
      )}
      {!column.disableSortBy && (
        <IconButton size="small">
          <Stack sx={{ color: 'secondary.light' }} {...(sort && { ...column.getHeaderProps(column.getSortByToggleProps()) })}>
            <CaretUp16Filled
              style={{
                fontSize: '0.625rem',
                color: column.isSorted && !column.isSortedDesc ? theme.palette.text.secondary : 'inherit'
              }}
            />
            <CaretDown16Filled
              style={{
                fontSize: '0.625rem',
                marginTop: -7,
                color: column.isSortedDesc ? theme.palette.text.secondary : 'inherit'
              }}
            />
          </Stack>
        </IconButton>
      )}
    </Stack>
  );
};
