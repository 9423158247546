import { Box, Typography, useTheme } from '@mui/material';
import { ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

interface IPreviewHtmlText {
  text: string;
  type: 'greetingText' | 'closingText' | 'optionalText';
  defaultFont?: string;
}
const PreviewHtmlText = ({ text, type, defaultFont }: IPreviewHtmlText) => {
  const intl = useIntl();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  function createMarkup(text: string) {
    return { __html: text };
  }

  const setPlaceholderIfEmpty = useCallback(
    (htmlString: string) => {
      const draft = htmlToDraft(htmlString);
      const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
      const extractedText = contentState.getPlainText().trim();
      if (!extractedText) {
        return !isDarkMode
          ? `<p><i style="color: #00000040">${intl.formatMessage({ id: `settings.template-editor-placeholder-${type}` })}</i></p>`
          : `<p><i style="color: #ffffff40">${intl.formatMessage({ id: `settings.template-editor-placeholder-${type}` })}</i></p>`;
      }
      return htmlString;
    },
    [intl, type]
  );

  return (
    <Box>
      <Typography
        fontFamily={!!defaultFont ? `${defaultFont} !important` : 'inherit'}
        dangerouslySetInnerHTML={createMarkup(setPlaceholderIfEmpty(text))}
      />
    </Box>
  );
};

export default PreviewHtmlText;
