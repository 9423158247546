import { Delete20Regular, LinkSquare20Regular } from '@fluentui/react-icons';
import { Draggable } from '@hello-pangea/dnd';
import { Button, Card, Collapse, Divider, Stack } from '@mui/material';
import EditableLabel from 'modules/builder/components/EditableLabel';
import OrganisationPlanTemplatePlaygroundSection from 'modules/organisation-plan-templates/components/OrganisationPlanTemplatePlaygroundSection';
import { IOrganisationPlanSection } from 'modules/organisation-plan-templates/types/organisationPlanSection';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ContextMenu, { IListOptionContextMenu } from 'shared/components/ContextMenu';
import RemoveDialog from 'shared/components/RemoveDialog';
import { useDispatch, useSelector } from 'store';
import {
  organizationPlanTemplateBuilderActiveLinkingSettings,
  selectAllOrgPlanLinks,
  selectDraggableItemOrganizationPlan,
  selectOrganizationPlanSectionById
} from 'store/organisation-plan-template/organisation-plan-template.selectors';
import {
  deleteOrganisationPlanSection,
  removeOrgPlanLinks,
  setTitleOfSection,
  updateActiveLinkingSettings
} from 'store/organisation-plan-template/organisation-plan-template.slice';
import { IOrgPlanLinkObjectForSelect } from '../OrganisationPlanTemplateLinkingTab/types';
import * as Styles from './OrganisationPlanTemplateSection.styles';

interface IOrganisationPlanTemplateSection {
  section: IOrganisationPlanSection;
  sectionIndex: number;
  isLastSection: boolean;
}

const OrganisationPlanTemplateSection = ({ section, sectionIndex, isLastSection }: IOrganisationPlanTemplateSection) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [expanded, setExpanded] = useState(true);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

  const itemWidget = useSelector(selectDraggableItemOrganizationPlan);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleTitleSection = (title: string) => {
    dispatch(setTitleOfSection({ sectionId: section.sectionId, title }));
  };

  const activeLinkingSettings = useSelector(organizationPlanTemplateBuilderActiveLinkingSettings);
  const sectionData = useSelector((state) => selectOrganizationPlanSectionById(state, section.sectionId));
  const allLinks: IOrgPlanLinkObjectForSelect[] = useSelector(selectAllOrgPlanLinks);

  const handleClickLinkSettings = () => {
    dispatch(updateActiveLinkingSettings({ sectionId: section.sectionId, widgetId: null }));
  };

  const deleteSection = () => {
    setIsDeleteConfirmationOpen(false);
    dispatch(deleteOrganisationPlanSection({ sectionId: section.sectionId }));
    const sectionLinks = allLinks.filter((link) => link?.target?.sectionId === section.sectionId);
    const widgetIdsForCurrentSection = section.layout.map(({ i }) => i);
    const widgetLinks = widgetIdsForCurrentSection.map((widgetId) => allLinks.filter((link) => link?.target?.widgetId === widgetId)).flat();
    const links = sectionLinks.concat(widgetLinks);
    if (links.length) {
      dispatch(removeOrgPlanLinks(links.map(({ linkId }) => linkId)));
    }
    if (activeLinkingSettings?.sectionId) {
      if (section.sectionId === activeLinkingSettings.sectionId) {
        dispatch(updateActiveLinkingSettings(null));
      }
    }
    if (activeLinkingSettings?.widgetId) {
      const isWidgetInSection = sectionData?.layout?.find((widget) => widget.i === activeLinkingSettings?.widgetId);
      if (isWidgetInSection) {
        dispatch(updateActiveLinkingSettings(null));
      }
    }
  };

  const handleDeleteSection = () => {
    setIsDeleteConfirmationOpen(true);
  };

  const listOptions: IListOptionContextMenu[] = [
    {
      messageId: 'organisation.settings-link',
      onClick: handleClickLinkSettings,
      icon: <LinkSquare20Regular />,
      isQuickAction: true
    },
    {
      messageId: 'users.delete',
      onClick: handleDeleteSection,
      icon: <Delete20Regular color="red" />,
      color: 'error',
      isQuickAction: true,
      isHidden: isLastSection && section.layout.length === 0
    }
  ];

  return (
    <>
      <Draggable draggableId={section.sectionId} index={sectionIndex}>
        {(provided) => (
          <Styles.SectionWrapper key={section.sectionId} ref={provided.innerRef} {...provided.draggableProps}>
            <Styles.SectionHeader isExpanded={expanded} {...provided.dragHandleProps}>
              <Stack width="100%" direction="row" gap="10px" justifyContent="space-between" alignItems="center">
                <EditableLabel value={section.name} name="organisation-plan-section-title" onSubmit={handleTitleSection} />
                <Stack direction="row" gap="5px">
                  <Button sx={{ textWrap: 'nowrap', minWidth: 'fit-content' }} onClick={handleExpandClick} size="small">
                    {expanded ? (
                      <FormattedMessage id="organisation-plan.collapse-section-btn" />
                    ) : (
                      <FormattedMessage id="organisation-plan.uncollapse-section-btn" />
                    )}
                  </Button>
                  <Divider orientation="vertical" flexItem />
                  <Card sx={{ boxShadow: 'none' }}>
                    <ContextMenu noIcon isWidget listOptions={listOptions} />
                  </Card>
                </Stack>
              </Stack>
            </Styles.SectionHeader>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <OrganisationPlanTemplatePlaygroundSection itemWidget={itemWidget} layout={section.layout} sectionId={section.sectionId} />
            </Collapse>
          </Styles.SectionWrapper>
        )}
      </Draggable>
      <RemoveDialog
        isOpen={isDeleteConfirmationOpen}
        isLoading={false}
        title={intl.formatMessage({ id: 'organisation-plan.delete-section-title' })}
        subText={intl.formatMessage({ id: 'organisation-plan.delete-section-subtext' })}
        close={() => setIsDeleteConfirmationOpen(false)}
        remove={deleteSection}
      />
    </>
  );
};

export default OrganisationPlanTemplateSection;
