import { Grid, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import { EEditorType, ValuesEditorType } from 'modules/editor/types/editor';
import { PdfTemplateParameters } from 'modules/settings/types';
import { memo } from 'react';
import EditorAndPreview from 'shared/components/DraftTextEditor/EditorAndPreview';

interface IEditorClosingText {
  parameters: PdfTemplateParameters;
  fontFamily: string;
  type: EEditorType;
}
const EditorClosingText = ({ parameters, fontFamily, type }: IEditorClosingText) => {
  const { values, setFieldValue } = useFormikContext<ValuesEditorType>();
  return (
    <Grid container sx={{ padding: '22px 0' }}>
      <Stack spacing={1} width="100%" sx={{ fontFamily: fontFamily }}>
        <EditorAndPreview
          entityType={type}
          type="closingText"
          defaultFont={fontFamily}
          editorType="PdfTemplate"
          htmlText={values?.settingsTemplate?.closingText ?? ''}
          parameters={parameters}
          onChange={(newValue) => setFieldValue('settingsTemplate', { ...values.settingsTemplate, closingText: newValue })}
        />
      </Stack>
    </Grid>
  );
};

export default memo(EditorClosingText);
