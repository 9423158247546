import { Delete16Regular, ReOrderDotsVertical20Regular } from '@fluentui/react-icons';
import { Draggable } from '@hello-pangea/dnd';
import { Box, FormControl, IconButton, TableCell, Typography } from '@mui/material';
import { FieldArrayRenderProps } from 'formik';
import { bruttoArticle } from 'modules/articles/utils/pricingCalc';
import { EEditorArticleItemType, IEditorArticle } from 'modules/editor/types/editor';
import {
  calculateAmount,
  calculateBruttoIfChangedNetto,
  calculateNettoIfChangedBrutto,
  getNameOfPriceField,
  getNameOfTotalPriceField
} from 'modules/invoices/utils/calculateHelpers';
import FormatPrice from 'modules/pricing-sidebar/components/FormatPrice';
import { TO_FIXED_FOR_PRICE } from 'modules/pricing-sidebar/constants/inputs';
import { QuestionnaireTaxType } from 'modules/questionnaires/types';
import { ComponentType, memo } from 'react';
import { useIntl } from 'react-intl';
import NumericInput from 'shared/components/NumberInputField';
import DebouncedTextField from 'shared/components/TextFieldDebounced';
import { IInvoiceEditor } from 'shared/types/invoice';
import { skipEnterInTextField } from 'shared/utils/form';
import * as Styles from './TableDynamicRow.styles';

interface ICustomInputHanlerParams {
  name: string;
  value: string | number;
  article: IEditorArticle;
  articleIndex: number;
  articleItemIndex: number;
  invoiceValueType: QuestionnaireTaxType;
}

interface ITableDynamicRow {
  currencySign: string | undefined;
  fontFamily: string;
}

const TableDynamicRow: ComponentType<(FieldArrayRenderProps & ITableDynamicRow) | void> = (props) => {
  const { formatMessage } = useIntl();
  if (!props?.push) {
    return null;
  }
  const {
    form: { handleChange, setFieldValue },
    currencySign,
    fontFamily,
    ...arrayHelpers
  } = props;
  const values = props.form.values as IInvoiceEditor;

  const customInputHandler = ({ name, value, article, articleItemIndex, invoiceValueType, articleIndex }: ICustomInputHanlerParams) => {
    const item = article.priceItems[articleItemIndex];
    const newBrutto = calculateBruttoIfChangedNetto(name, value, invoiceValueType, article?.tax);
    const newNetto = calculateNettoIfChangedBrutto(name, value, invoiceValueType, article?.tax);
    const updatedItem = {
      ...item,
      [name]: value,
      ...newNetto,
      ...newBrutto
    };
    const newArticleItems = article.priceItems.map((articleItem, index) => (index === articleItemIndex ? updatedItem : articleItem));
    const newArticle: IEditorArticle = {
      ...article,
      totalNet: calculateAmount(newArticleItems, QuestionnaireTaxType.Net),
      totalGross: calculateAmount(newArticleItems, QuestionnaireTaxType.Gross),
      priceItems: newArticleItems
    };
    setFieldValue(`articles.${articleIndex}`, newArticle);
  };

  const numberInputTaxHandler = ({
    value,
    article,
    articleIndex
  }: {
    value: string | number;
    article?: IEditorArticle;
    articleIndex: number;
  }) => {
    const newPriceItemsArticle =
      article?.priceItems?.map((item) => ({
        ...item,
        priceBrutto: bruttoArticle(item.priceNetto ?? 0, !!value ? Number(value) : 0)
      })) ?? [];
    setFieldValue(`articles.${articleIndex}`, {
      ...article,
      priceItems: newPriceItemsArticle,
      tax: value,
      totalNet: calculateAmount(newPriceItemsArticle, QuestionnaireTaxType.Net),
      totalGross: calculateAmount(newPriceItemsArticle, QuestionnaireTaxType.Gross)
    } as IEditorArticle);
  };

  const priceFieldName = getNameOfPriceField(values.taxType);
  const totalPriceFieldName = getNameOfTotalPriceField(values.taxType);

  return (
    <>
      {values?.articles?.map((article, index) => (
        <Draggable key={article.id ?? article._innerId} draggableId={article.id ? `${article.id}` : `${article._innerId}`} index={index}>
          {(provided) => (
            <Styles.TableRow
              fontFamily={fontFamily}
              key={article.id ?? article._innerId}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <TableCell sx={{ cursor: 'grab !important' }} className="first-cell">
                <ReOrderDotsVertical20Regular className="dragHandle" />
                <Typography textAlign="right" sx={{ width: '20px' }} className="index">
                  {index + 1}
                </Typography>
              </TableCell>
              <TableCell width="600px">
                <FormControl fullWidth>
                  <DebouncedTextField
                    onKeyDown={skipEnterInTextField}
                    multiline
                    fullWidth
                    name={`articles.${index}.name`}
                    value={values?.articles?.[index]?.name}
                    onChange={handleChange}
                    placeholder={formatMessage({ id: 'invoices.invoice-editor-description-placeholder' })}
                  />
                </FormControl>
              </TableCell>
              <TableCell>
                <NumericInput
                  width="100px"
                  name={`articles.${index}.tax`}
                  isDecimal
                  maxDecimalDigits={2}
                  showDecimalZeros
                  value={values?.articles?.[index]?.tax ?? 0}
                  onHandleInputChange={(value) => numberInputTaxHandler({ value, article, articleIndex: index })}
                  textAlign="right"
                />
              </TableCell>
              <TableCell>
                {article?.priceItems?.map(
                  (item, itemIndex) =>
                    item?.type !== EEditorArticleItemType.SecondUnit && (
                      <Box key={item.id ?? itemIndex} mt={!!itemIndex ? '4px' : 'none'}>
                        <NumericInput
                          width="110px"
                          name={priceFieldName}
                          value={item?.[priceFieldName] ?? 0}
                          onHandleInputChange={(value: number) =>
                            customInputHandler({
                              value,
                              name: priceFieldName,
                              article,
                              articleIndex: index,
                              articleItemIndex: itemIndex,
                              invoiceValueType: values.taxType
                            })
                          }
                          isCurrency
                          isDecimal
                          maxDecimalDigits={TO_FIXED_FOR_PRICE}
                          showDecimalZeros
                          textAlign="right"
                          hideAdornment
                        />
                      </Box>
                    )
                )}
              </TableCell>
              <TableCell width="300px">
                {article?.priceItems?.map((item, itemIndex) => (
                  <Box key={item.id ?? itemIndex} mt={!!itemIndex ? '4px' : 'none'}>
                    <DebouncedTextField
                      onKeyDown={skipEnterInTextField}
                      fullWidth
                      onChange={handleChange}
                      name={`articles.${index}.priceItems.${itemIndex}.unit`}
                      value={item.unit}
                      placeholder={formatMessage({ id: 'invoices.invoice-editor-unit-placeholder' })}
                    />
                  </Box>
                ))}
              </TableCell>
              <TableCell>
                {article?.priceItems?.map((item, itemIndex) => (
                  <Box key={item.id ?? itemIndex} mt={!!itemIndex ? '4px' : 'none'}>
                    <NumericInput
                      width="120px"
                      name="count"
                      value={item.count ?? 0}
                      isDecimal
                      maxDecimalDigits={2}
                      showDecimalZeros
                      textAlign="right"
                      allowNegative
                      onHandleInputChange={(value) =>
                        customInputHandler({
                          value,
                          name: 'count',
                          article,
                          articleIndex: index,
                          articleItemIndex: itemIndex,
                          invoiceValueType: values.taxType
                        })
                      }
                    />
                  </Box>
                ))}
              </TableCell>
              <TableCell align="right" sx={{ minWidth: 130 }}>
                <Typography margin="7px">
                  <FormatPrice value={article[totalPriceFieldName] ?? 0} isHideSymbol />
                </Typography>
              </TableCell>
              <TableCell>
                <IconButton size="small" onClick={arrayHelpers.handleRemove(index)} color="error">
                  <Delete16Regular />
                </IconButton>
              </TableCell>
            </Styles.TableRow>
          )}
        </Draggable>
      ))}
    </>
  );
};

export default memo(TableDynamicRow);
