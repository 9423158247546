import { ArticlePriceType } from 'modules/articles/types';
import PreviewAnswerWrapper from 'modules/organisation-plan-preview/components/Shared/PreviewAnswerWrapper';
import PreviewWidgetWrapper from 'modules/organisation-plan-preview/components/Shared/PreviewWidgetWrapper';
import { renderInputLabel } from 'modules/organisation-plan-preview/utils/renderInputLabelHandler';
import { DisplayMode } from 'modules/organisation-plan-templates/types';
import { OrganisationPlanInputNumberWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanInputNumberWithReplacementType';
import { Layout } from 'react-grid-layout';

interface IPreviewNumberInputWidgetProps {
  element: OrganisationPlanInputNumberWithReplacementType;
  layoutItem: Layout;
}

const PreviewNumberInputWidget = ({ element, layoutItem }: IPreviewNumberInputWidgetProps) => {
  const {
    replacementLabel,
    label,
    isMainLabelHidden,
    widgetId,
    sectionId,
    value,
    secondUnitValue,
    isBottomDivider,
    isHighlighted,
    displayMode,
    listingOption,
    isUnitLabelHidden
  } = element;

  const usedArticle = element?.articles?.find((article) => article?.widgetId === element?.linkedWidgetId)?.article;
  const isColumnMode = (displayMode ?? DisplayMode.row) === DisplayMode.column;
  const isPricePerGuest =
    usedArticle?.price.articlePriceType === ArticlePriceType.FlatRate && !usedArticle?.price.flatRateInfo.isTotalPrice;
  const isOnRequest = usedArticle?.price.articlePriceType === ArticlePriceType.onRequest;
  const primaryUnit = isOnRequest ? usedArticle?.price?.onRequestInfo.onRequestPrice : usedArticle?.price?.unit;
  const secondaryUnit = isPricePerGuest ? usedArticle?.price?.flatRateInfo?.secondUnit : usedArticle?.price?.numericallyInfo?.secondUnit;
  const numericFirstValue = primaryUnit && !isUnitLabelHidden ? `${value} (${primaryUnit})` : `${value ?? ''}`;
  const numericSecondValue = secondaryUnit && !isUnitLabelHidden ? `${secondUnitValue} (${secondaryUnit ?? ''})` : secondUnitValue;
  const numericValue = value && secondUnitValue ? `${numericFirstValue}; ${numericSecondValue}` : numericFirstValue;

  return (
    <PreviewWidgetWrapper
      isBottomDivider={isBottomDivider}
      isHighlighted={isHighlighted}
      widgetId={widgetId}
      sectionId={sectionId}
      layoutItem={layoutItem}
      questionLabel={renderInputLabel(replacementLabel, label)}
      isQuestionLabelHidden={!!isMainLabelHidden || !isColumnMode}
    >
      <PreviewAnswerWrapper
        index={0}
        displayMode={displayMode ?? DisplayMode.row}
        listingOption={listingOption}
        questionLabel={renderInputLabel(replacementLabel, label)}
        numericValueLabel={numericValue}
        isLabelHidden={isMainLabelHidden}
      />
    </PreviewWidgetWrapper>
  );
};

export default PreviewNumberInputWidget;
