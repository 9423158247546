import { ArrowSwap16Regular, Eye16Regular, EyeOff16Regular } from '@fluentui/react-icons';
import { Divider, Stack, Tooltip, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { HideLabelButton, ReplacementTextButton } from './Button.styles';

interface IReplacementTextButtons {
  isReplacementValueShown?: boolean;
  replaceValueCallback?: () => void;
  isLabelShown?: boolean;
  labelShownCallback?: () => void;
}

const ReplacementTextButtons = ({
  isReplacementValueShown,
  replaceValueCallback,
  isLabelShown,
  labelShownCallback
}: IReplacementTextButtons) => {
  const intl = useIntl();
  const theme = useTheme();

  const replacementMessage = intl.formatMessage({
    id: isReplacementValueShown ? 'organisation.remove-replacement' : 'organisation.add-replacement'
  });

  const labelShownMessage = intl.formatMessage({ id: isLabelShown ? 'organisation.hide-label' : 'organisation.show-label' });

  return (
    <Stack
      ml={1}
      direction="row"
      boxShadow={theme.palette.mode === 'dark' ? `0 0 0  1.5px  #ffffff20` : `0 0 0  1.5px  #00000020`}
      borderRadius="6px"
    >
      {!isLabelShown && (
        <Tooltip arrow enterDelay={2000} placement="left" title={replacementMessage}>
          <ReplacementTextButton isActive={isReplacementValueShown} onClick={replaceValueCallback}>
            <ArrowSwap16Regular />
          </ReplacementTextButton>
        </Tooltip>
      )}
      {!isReplacementValueShown && !isLabelShown && <Divider orientation="vertical" flexItem sx={{ zIndex: 2 }} />}
      {!isReplacementValueShown && (
        <Tooltip arrow enterDelay={2000} placement={isLabelShown ? 'left' : 'right'} title={labelShownMessage}>
          <HideLabelButton isActive={isLabelShown} onClick={labelShownCallback}>
            {isLabelShown ? <EyeOff16Regular /> : <Eye16Regular />}
          </HideLabelButton>
        </Tooltip>
      )}
    </Stack>
  );
};

export default ReplacementTextButtons;
