import { Button, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IEventsTableColumnWrapperStylesProps {
  customMaxWidth: number | null;
}

interface ITableProps extends TooltipProps {
  color: string;
}

export const EventsTableColumnWrapper = styled(Typography)<IEventsTableColumnWrapperStylesProps>(({ customMaxWidth }) => ({
  fontWeight: 700,
  fontSize: '12px',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre-wrap',
  overflow: 'hidden',
  width: 'max-content',
  maxWidth: customMaxWidth ?? undefined
}));

export const HtmlTooltip = styled(({ className, ...props }: ITableProps) => <Tooltip arrow {...props} classes={{ popper: className }} />)(
  ({ theme, color }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.secondary.lighter,
      color: color,
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      boxShadow: `0 0 0 0.1rem ${color}`,
      borderRadius: '6px',
      fontWeight: 600
    },
    [`& .${tooltipClasses.arrow}`]: {
      '&::before': {
        boxShadow: `0 0 0 0.1rem ${color}`
      }
    }
  })
);

export const MainActionButton = styled(Button)(({ theme }) => ({
  border: `1px solid #00000000`,
  padding: '2px 10px',
  color: theme.palette.text.primary,
  justifyContent: 'left',
  fontSize: '0.875rem',
  '&:hover': {
    color: theme.palette.mode === 'light' ? theme.palette.secondary.A200 : theme.palette.secondary.main,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.secondary.light : theme.palette.secondary.A200
  }
}));
