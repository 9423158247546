import { IOfferTemplateFormData } from 'shared/types/settingsOffer';
import { ITemplateFormData } from 'shared/types/settingsTemplate';
import { ISelectParams } from './types';

export interface DateSyntaxFormat extends ISelectParams {
  format?: string;
}

export interface IPdfTemplatesVariables {
  key: string;
  variable: string;
  intlId: string;
  description?: string;
}

export const hexColors = {
  orange: '#f28c28',
  darkGray: '#3e3c3c',
  lightGray: '#cecece',
  black: '#000000',
  green: '#00a36c',
  blue: '#1890ff',
  red: '#dc143c',
  white: '#ffffff',
  gray: '#8c8c8c'
};

export const DATA_SYNTAX: Array<DateSyntaxFormat> = [
  { label: 'DD-MM-YYYY', value: 'dmyLine', format: 'dd-MM-yyyy' },
  { label: 'DD.MM.YYYY', value: 'dmyDot', format: 'dd.MM.yyyy' },
  { label: 'DD/MM/YYYY', value: 'dmySlash', format: 'dd/MM/yyyy' }
];

export const TEXT_SIZE_OPTIONS: Array<string> = [
  '10px',
  '11px',
  '12px',
  '13px',
  '14px',
  '15px',
  '16px',
  '17px',
  '18px',
  '19px',
  '20px',
  '21px',
  '22px',
  '23px',
  '24px',
  '25px',
  '26px',
  '27px',
  '28px',
  '29px',
  '30px',
  '32px',
  '34px',
  '36px',
  '38px',
  '40px'
];

export const FONT_FAMILY_OPTIONS = [
  'Abhaya Libre',
  'Alegreya',
  'Aleo',
  'Alex Brush',
  'Allura',
  'Arapey',
  'Archivo',
  'Asap Condensed',
  'Assistant',
  'Arimo',
  'Bad Script',
  'Barlow',
  'Berkshire Swash',
  'Bilbo Swash Caps',
  'Bitter',
  'Brawler',
  'Caladea',
  'Carattere',
  'Carme',
  'Caveat Brush',
  'Caveat',
  'Comic Neue',
  'Dancing Script',
  'Euphoria Script',
  'Expletus Sans',
  'Felipa',
  'Fira Code',
  'Fira Sans',
  'Great Vibes',
  'Imperial Script',
  'Inconsolata',
  'Inter',
  'JetBrains Mono',
  'Jim Nightshade',
  'Kalnia',
  'Lato',
  'Lexend Deca',
  'Lora',
  'M PLUS Code Latin',
  'Manrope',
  'Mea Culpa',
  'Merriweather Sans',
  'Merriweather',
  'Montez',
  'Montserrat',
  'Mulish',
  'Noto Sans',
  'Noto Serif Display',
  'Noto Serif',
  'Nunito',
  'Nunito Sans',
  'Old Standard TT',
  'Open Sans',
  'Oswald',
  'Patrick Hand',
  'PT Sans',
  'PT Serif',
  'Pacifico',
  'Parisienne',
  'Pinyon Script',
  'Playfair Display',
  'Poppins',
  'Public Sans',
  'Raleway',
  'Roboto',
  'Rokkitt',
  'Rubik Doodle Shadow',
  'Rubik Scribble',
  'Rubik',
  'Satisfy',
  'Space Mono',
  'Spectral',
  'Titillium Web',
  'UnifrakturMaguntia',
  'Victor Mono',
  'Whisper',
  'Ysabeau Infant'
] as const;

export const FONT_FAMILY_INVOICE_OPTIONS = [
  'Arimo',
  'Archivo',
  'Manrope',
  'Lato',
  'Lora',
  'Merriweather',
  'Montserrat',
  'Noto Sans',
  'Noto Serif',
  'Nunito Sans',
  'Open Sans',
  'PT Sans',
  'PT Serif',
  'Raleway',
  'Roboto',
  'Rubik',
  'Open Sans',
  'Spectral',
  'Titillium Web',
  'Fira Sans',
  'Fira Code',
  'Space Mono',
  'M PLUS Code Latin',
  'JetBrains Mono'
] as const;

export const SETTINGS_ROUTES = {
  SETTINGS: '/settings',
  GENERAL_SETTINGS: '/settings/general-settings',
  VENUES: '/settings/venues',
  SETTINGS_VENUE: 'settings/venue',
  VENUE_CREATE: '/settings/venue/create',
  VENUE_EDIT: (id: string) => `/settings/venue/${id}`,
  INVOICES: '/settings/invoices',
  EMAIL_TEMPLATES: '/settings/email-templates',
  PERSONAL: '/settings/personal',
  EMAIL_TEMPLATE: (code?: string) => `/settings/email-template/${code ? code : ''}`,
  INFO: '/settings/info',
  OFFERS: '/settings/offers'
};

export const INVOICE_START_NUMBER_MAX_LENGHT = 9;

export const DEFAULT_TEMPLATE_DATA: ITemplateFormData = {
  name: '',
  senderAddress: {
    corporateName: '',
    city: '',
    street: '',
    zipCode: '',
    country: ''
  },
  font: FONT_FAMILY_INVOICE_OPTIONS[6].replace(/\s/g, ''),
  documentSubject: '',
  welcomeText: '',
  closingText: '',
  logoImagePath: undefined,
  footerTexts: [{ text: '' }]
};

export const DEFAULT_TEMPLATE_DATA_OFFER: IOfferTemplateFormData = {
  name: '',
  senderAddress: {
    corporateName: '',
    city: '',
    street: '',
    zipCode: '',
    country: ''
  },
  font: FONT_FAMILY_INVOICE_OPTIONS[6].replace(/\s/g, ''),
  documentSubject: '',
  welcomeText: '',
  optionalText: '',
  closingText: '',
  logoImagePath: undefined,
  footerTexts: [{ text: '' }]
};

export const NEW_TEMPLATE_ID = 'new-template';

export const PDF_OFFER_TEXT_VARIABLES: IPdfTemplatesVariables[] = [
  {
    key: 'go_to_request',
    intlId: 'settings.template-variable-go-to-request',
    variable: '[[go_to_request]]'
  },
  {
    key: 'offer_date',
    intlId: 'settings.template-variable-offer-date',
    variable: '[[offer_date]]'
  },
  {
    key: 'requested_date',
    intlId: 'settings.template-variable-requested-date',
    variable: '[[requested_date]]'
  },
  {
    key: 'recipient_name',
    intlId: 'settings.template-variable-recipient-name',
    variable: '[[recipient_name]]'
  },
  {
    key: 'recipient_surname',
    intlId: 'settings.template-variable-recipient-surname',
    variable: '[[recipient_surname]]'
  },
  {
    key: 'recipient_partner_name',
    intlId: 'settings.template-variable-recipient-partner-name',
    variable: '[[recipient_partner_name]]'
  },
  {
    key: 'recipient_partner_surname',
    intlId: 'settings.template-variable-recipient-partner-surname',
    variable: '[[recipient_partner_surname]]'
  },
  {
    key: 'event_manager_name',
    intlId: 'settings.template-variable-event-manager-name',
    variable: '[[event_manager_name]]'
  },
  {
    key: 'event_manager_surname',
    intlId: 'settings.template-variable-event-manager-surname',
    variable: '[[event_manager_surname]]'
  }
];

export const PDF_INVOICE_TEXT_VARIABLES: IPdfTemplatesVariables[] = [
  {
    key: 'invoice_date',
    intlId: 'settings.template-variable-invoice-date',
    variable: '[[invoice_date]]'
  },
  {
    key: 'performance_date',
    intlId: 'settings.template-variable-performance-date',
    variable: '[[performance_date]]'
  },
  {
    key: 'recipient_name',
    intlId: 'settings.template-variable-recipient-name',
    variable: '[[recipient_name]]'
  },
  {
    key: 'recipient_surname',
    intlId: 'settings.template-variable-recipient-surname',
    variable: '[[recipient_surname]]'
  },
  {
    key: 'recipient_partner_name',
    intlId: 'settings.template-variable-recipient-partner-name',
    variable: '[[recipient_partner_name]]'
  },
  {
    key: 'recipient_partner_surname',
    intlId: 'settings.template-variable-recipient-partner-surname',
    variable: '[[recipient_partner_surname]]'
  },
  {
    key: 'event_manager_name',
    intlId: 'settings.template-variable-event-manager-name',
    variable: '[[event_manager_name]]'
  },
  {
    key: 'event_manager_surname',
    intlId: 'settings.template-variable-event-manager-surname',
    variable: '[[event_manager_surname]]'
  }
];
