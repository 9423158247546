import { Card, useTheme } from '@mui/material';
import { useGetInvoiceEditorById } from 'api/hooks/invoices/useGetInvoiceEditorById';
import { useGetInvoiceItems } from 'api/hooks/invoices/useGetInvoiceItems';
import { usePatchUpdateInvoice } from 'api/hooks/invoices/usePatchUpdateInvoice';
import { useUpdateInvoiceItems } from 'api/hooks/invoices/useUpdateInvoiceItems';
import { Form, FormikProvider, useFormik } from 'formik';
import EditorFooter from 'modules/editor/components/EditorFooter';
import EditorResultCalculation from 'modules/editor/components/EditorResultCalculation';
import { EEditorType } from 'modules/editor/types/editor';
import GreetingTextImportButtons from 'modules/invoices/components/GreetingTextImportButtons/index';
import InvoiceEditorDetails from 'modules/invoices/components/InvoiceEditorDetails';
import InvoiceEditorHeader from 'modules/invoices/components/InvoiceEditorHeader';
import InvoiceEditorTable from 'modules/invoices/components/InvoiceEditorTable';
import { prepareDataForItemsRequest, prepareDataForPatchRequest, prepareInvoiceEditorToForm } from 'modules/invoices/utils/prepareData';
import { FONT_FAMILY_OPTIONS } from 'modules/settings/constants';
import { PdfTemplateParameters } from 'modules/settings/types';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import PageTitle from 'shared/components/PageTitle';
import { useFormatCurrentDate } from 'shared/hooks/regionalSettings';
import { useInfoMessage } from 'shared/hooks/useInfoMessage';
import { IInvoiceEditor } from 'shared/types/invoice';
import { textError } from 'shared/utils/infoText';
import { useSelector } from 'store';
import { selectPaginationInvoices } from 'store/pagination/pagination.selectors';

const InvoiceEditor = () => {
  const intl = useIntl();
  const theme = useTheme();
  const formatFn = useFormatCurrentDate();
  const [fontFamily, setFontFamily] = useState<string>('');
  const { invoiceId } = useParams();
  const pagination = useSelector(selectPaginationInvoices);
  const { successMessage, errorMessage } = useInfoMessage();
  const invoiceIdNumber = invoiceId ? Number(invoiceId) : undefined;

  const { data: invoiceItems } = useGetInvoiceItems(invoiceIdNumber);
  const { data: invoiceEditorData, isLoading: isEditorLoading } = useGetInvoiceEditorById({ invoiceId: invoiceIdNumber });
  const { mutateAsync: saveEditorAsync, isLoading: isEditorSaving } = usePatchUpdateInvoice({
    id: invoiceIdNumber,
    pagination,
    options: {
      onError(error) {
        errorMessage(textError(error));
      }
    }
  });

  const { mutateAsync: saveItemsAsync, isLoading: isInvoiceItemsSaving } = useUpdateInvoiceItems({});

  const formik = useFormik<IInvoiceEditor>({
    initialValues: prepareInvoiceEditorToForm(invoiceEditorData),
    onSubmit: async (values) => {
      await saveEditorAsync(prepareDataForPatchRequest(values));
      const itemsWithDefaultValues = prepareDataForItemsRequest(values.articles);
      await saveItemsAsync(
        { invoiceId: values.id!, items: itemsWithDefaultValues },
        {
          onSuccess: () => {
            successMessage(intl.formatMessage({ id: 'invoices.update-invoice-success' }));
          },
          onError(error) {
            errorMessage(textError(error));
          }
        }
      );
    }
  });
  const { resetForm, values } = formik;

  useEffect(() => {
    if (!invoiceEditorData || !invoiceItems) return;
    const sortedInvoiceItems = invoiceItems.sort((item1, item2) => item1.order - item2.order);
    resetForm({ values: prepareInvoiceEditorToForm(invoiceEditorData, sortedInvoiceItems) });
  }, [resetForm, invoiceEditorData, invoiceItems]);

  const parameters: PdfTemplateParameters = useMemo(
    () => ({
      invoice_date: formatFn(invoiceEditorData?.invoiceDate?.toString() ?? ''),
      performance_date:
        formatFn(invoiceEditorData?.event?.eventDate?.toString() ?? '') ?? formatFn(invoiceEditorData?.invoiceDate?.toString() ?? ''),
      recipient_name: values.recipient?.name ?? '',
      recipient_surname: values.recipient?.surname ?? '',
      recipient_partner_name: values.recipient?.details?.partner?.name ?? '',
      recipient_partner_surname: values.recipient?.details?.partner?.surname ?? '',
      event_manager_name: values.data?.eventManager?.name ?? '',
      event_manager_surname: values.data?.eventManager?.surname ?? '',
      greeting_formal:
        values.recipient?.gender === 'Female'
          ? `Sehr geehrte Frau ${values.recipient?.surname ?? ''},`
          : `Sehr geehrter Herr ${values.recipient?.surname ?? ''},`,
      greeting_partner_formal: !!values.recipient?.details?.partner?.name
        ? values.recipient?.gender === 'Female'
          ? `Sehr geehrte Frau ${values.recipient?.details?.partner?.surname ?? ''},`
          : `Sehr geehrter Herr ${values.recipient?.details?.partner?.surname ?? ''},`
        : '',
      greeting_informal:
        values.recipient?.gender === 'Female' ? `Liebe ${values.recipient?.name ?? ''},` : `Lieber ${values.recipient?.name ?? ''},`,
      greeting_partner_informal: !!values.recipient?.details?.partner?.name
        ? values.recipient?.gender === 'Female'
          ? `Liebe ${values.recipient?.details?.partner?.name ?? ''},`
          : `Lieber ${values.recipient?.details?.partner?.name ?? ''},`
        : ''
    }),
    [invoiceEditorData, values]
  );

  useEffect(() => {
    const settingsTemplate = values.settingsTemplate as unknown as { font: string; invoiceFont: string };
    const fontFamily = settingsTemplate?.invoiceFont ?? values.settingsTemplate?.font ?? theme.typography.fontFamily;
    const readableFont =
      FONT_FAMILY_OPTIONS.find((font) => font.replace(/\s+/g, '').toLowerCase() === fontFamily?.toLowerCase()) ??
      theme.typography.fontFamily ??
      '';
    setFontFamily(readableFont);
  }, [values.settingsTemplate, theme.typography.fontFamily]);

  return (
    <>
      <PageTitle title={invoiceEditorData?.number} />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
          <InvoiceEditorHeader isLoading={isEditorLoading || isEditorSaving || isInvoiceItemsSaving} />
          <Card
            sx={{
              padding: '60px 40px 30px 40px',
              boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
              width: { xs: '100%', lg: '80vw', xl: '70vw' },
              maxWidth: '1200px',
              minWidth: '820px',
              margin: '0 auto',
              marginBottom: 10
            }}
          >
            <InvoiceEditorDetails fontFamily={fontFamily} venue={invoiceEditorData?.venue} />
            <GreetingTextImportButtons parameters={parameters} fontFamily={fontFamily} />
            <InvoiceEditorTable fontFamily={fontFamily} />
            <EditorResultCalculation fontFamily={fontFamily} />
            <EditorFooter type={EEditorType.Invoices} parameters={parameters} fontFamily={fontFamily} />
          </Card>
        </Form>
      </FormikProvider>
    </>
  );
};

export default InvoiceEditor;
