import PreviewAnswerWrapper from 'modules/organisation-plan-preview/components/Shared/PreviewAnswerWrapper';
import PreviewWidgetWrapper from 'modules/organisation-plan-preview/components/Shared/PreviewWidgetWrapper';
import { renderInputLabel } from 'modules/organisation-plan-preview/utils/renderInputLabelHandler';
import { DisplayMode } from 'modules/organisation-plan-templates/types';
import { OrganisationPlanInputTextWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanInputTextWithReplacementType';
import { Layout } from 'react-grid-layout';

interface IPreviewTextWithLabelInputWidgetProps {
  element: OrganisationPlanInputTextWithReplacementType;
  layoutItem: Layout;
}

const PreviewTextWithLabelInputWidget = ({ element, layoutItem }: IPreviewTextWithLabelInputWidgetProps) => {
  const {
    replacementLabel,
    label,
    value,
    isMainLabelHidden,
    widgetId,
    sectionId,
    isBottomDivider,
    isHighlighted,
    listingOption,
    displayMode
  } = element;
  const renderValue = value ? value : '';

  return (
    <PreviewWidgetWrapper
      isBottomDivider={isBottomDivider}
      isHighlighted={isHighlighted}
      widgetId={widgetId}
      sectionId={sectionId}
      layoutItem={layoutItem}
      questionLabel={renderInputLabel(replacementLabel, label)}
      isQuestionLabelHidden={!!isMainLabelHidden || (displayMode ?? DisplayMode.column) !== DisplayMode.column || false}
    >
      <PreviewAnswerWrapper
        index={0}
        questionLabel={renderInputLabel(replacementLabel, label)}
        answerLabel={renderValue}
        isLabelHidden={!value}
        listingOption={listingOption}
        displayMode={displayMode}
      />
    </PreviewWidgetWrapper>
  );
};

export default PreviewTextWithLabelInputWidget;
