import { Navigate, useLocation } from 'react-router-dom';
import { GuardProps } from 'modules/auth/types';
import { QUESTIONNAIRES_EVENT_PREVIEW_ROUTES } from 'modules/events/constants';
import { ALLOWED_CUSTOMER_ROUTES, CUSTOMER_QUESTIONNAIRES_ROUTES } from 'modules/questionnaires-customer/constants';
import { useAuth } from 'shared/contexts/AuthContext';
import { PREVIEW, QUESTIONNAIRES_ROUTES } from 'modules/questionnaires/constants';
import { QUESTIONNAIRES_REQUEST_PREVIEW_ROUTES } from 'modules/requests/constant/paths';
import { IS_ANONYMOUS } from 'modules/auth/constants';

/**
 * @param children - pass your own component
 * @param role - you can provide a role if you want to limit access to Route
 */

const AuthGuard = ({ children, role }: GuardProps) => {
  const { isLoggedIn, user, logout } = useAuth();
  const isAnonymousSession = localStorage.getItem(IS_ANONYMOUS) === 'true' ? true : false;

  const location = useLocation();
  const isPreview =
    location.pathname.includes(QUESTIONNAIRES_ROUTES.QUESTIONNAIRES_PREVIEW_LINK) ||
    location.pathname.includes(QUESTIONNAIRES_EVENT_PREVIEW_ROUTES.ROOT_LINK) ||
    location.pathname.includes(QUESTIONNAIRES_REQUEST_PREVIEW_ROUTES.ROOT_LINK);
  const isCustomerOnAllowedPage = ALLOWED_CUSTOMER_ROUTES.some((link) => location.pathname.includes(link));

  if (isPreview && !isLoggedIn) {
    localStorage.setItem(PREVIEW, location.pathname);
  }

  if (!isLoggedIn || !user) {
    return <Navigate replace to="/" />;
  }
  if (user.role === 'customer' && !isCustomerOnAllowedPage) {
    if (isAnonymousSession) {
      logout();
      return;
    } else return <Navigate replace to={CUSTOMER_QUESTIONNAIRES_ROUTES.customerQuestionnairesAbsoluteRoute} />;
  }

  if (role) {
    return role?.some((userRole) => userRole === user.role) ? children : <Navigate replace to="/" />;
  }
  return children;
};

export default AuthGuard;
